// import React, { useEffect, useRef, useState } from 'react'
// import { useGetCustomerJobUpdateByIdQuery, usePosCustomerJobUpdateByIdMutation, useUpdateTemplateByIdMutation } from '../../Redux/API/atsSlice'
// import { useSelector } from 'react-redux'
// import DynamicTextInput from '../DynamicInputs/DynamicTextInput'
// import { useLocation, useParams } from 'react-router-dom'
// import "./EditJob.css"
// import { showToast } from '../ToastContainer/ToastProvider'

// function EditJob() {
//     const { id } = useParams()
//     const MovableInput = useRef()
//     const isAuth = useSelector(state => state.auth)
//     const [customInputs, setCustomInput] = useState([])
//     const [EditJob] = usePosCustomerJobUpdateByIdMutation()
//     const [Remove, setRemove] = useState(false)

//     // fetching job data through Jobid
//     const {
//         data,
//         refetch,
//         isSuccess
//     } = useGetCustomerJobUpdateByIdQuery({
//         domain_name: isAuth?.user?.domain_name,
//         Jobid: id
//     })

//     useEffect(() => {
//         refetch()
//     }, [])

//     useEffect(() => {
//         if (data?.status) {
//             setCustomInput(data?.message)
//         }
//     }, [data?.status])

//     function convertToMySQLTimestamp(isoTimestamp) {
//         const date = new Date(isoTimestamp);

//         const year = date.getFullYear();
//         const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
//         const day = String(date.getDate()).padStart(2, '0');
//         const hours = String(date.getHours()).padStart(2, '0');
//         const minutes = String(date.getMinutes()).padStart(2, '0');
//         const seconds = String(date.getSeconds()).padStart(2, '0');

//         return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
//     }

//     function ensureMySQLDateTime(dateTimeString) {
//         if (!dateTimeString) return null; // Return null if no value is provided

//         const parsedDate = new Date(dateTimeString);
//         if (isNaN(parsedDate.getTime())) {
//             return null; // Return null if the date is invalid
//         }

//         // Extract and format parts for MySQL DATETIME format
//         const year = parsedDate.getFullYear();
//         const month = String(parsedDate.getMonth() + 1).padStart(2, '0'); // Months are 0-based
//         const day = String(parsedDate.getDate()).padStart(2, '0');
//         const hours = String(parsedDate.getHours()).padStart(2, '0');
//         const minutes = String(parsedDate.getMinutes()).padStart(2, '0');
//         const seconds = String(parsedDate.getSeconds()).padStart(2, '0');

//         return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
//         }

//     const handleListener = async () => {
//         try {
//             const data = {}
//             customInputs && customInputs.forEach((item, i) => {
//                 if (JSON.stringify(item) !== '{}') {
//                     if (item?.datatype === "boolean") {
//                         console.log("item", item?.valueOftheField)
//                         data[`${item?.ColumnName}`] = item?.valueOftheField === "true" ? true : item?.valueOftheField === 'false' ? false : item?.valueOftheField === true ? true : item?.valueOftheField === "null" ? false : false
//                     } else if (item?.datatype === "int") {
//                         data[`${item?.ColumnName}`] = parseInt(item?.valueOftheField === null ? 0 : item?.valueOftheField)
//                     } else if (item?.datatype === "timestamp") {
//                         data[`${item?.ColumnName}`] = ensureMySQLDateTime(item?.valueOftheField)
//                     }
//                     else {
//                         data[`${item?.ColumnName}`] = item?.valueOftheField
//                     }
//                 }
//             })
//             try {
//                 const response = await EditJob({
//                     domain_name: isAuth?.user.domain_name,
//                     Jobid: id,
//                     data: data
//                 })
//                 console.log("Res", response)
//                 showToast(response.data.status,response.data.message)
//             } catch (err) {
//                 console.log(err)
//             }
//         } catch (err) {
//             console.log(err)
//         }

//     }

//     return (
//         <>

//             <div className="create-job-container" style={{ display: "flex", flexDirection: "row" }}>
//                 {/* <EditCreateJobLayout setCustomInput={setCustomInput} customInputs={customInputs} handleInput={handleInput} setHandleInput={setHandleInput} /> */}
//                 <div>
//                     {customInputs && customInputs.map((item, i) => {
//                         return (
//                             <div className="dynamic-input-container">
//                                 {JSON.stringify(item) !== '{}' ?
//                                     <DynamicTextInput key={i} remove={Remove} onChangeRemove={setRemove} index={i} {...item} value={customInputs} customInputs={customInputs} onChangeCustomInput={setCustomInput} onChangeValue={setCustomInput}
//                                     //     {...handleInput[i]}
//                                     /> : <></>
//                                 }

//                             </div>
//                         )
//                     })

//                     }
//                     <button onClick={() => {
//                         handleListener()
//                     }} className="edit-save-changes-button">Save Changes</button>
//                     <button className='edit-cancel-button'>
//                         <a href='/ats/dashboard/?type=job_openings'>Cancel</a>
//                     </button>
//                 </div>

//             </div>
//         </>
//     )
// }

// export default EditJob;

import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
  useGetCustomerJobUpdateByIdQuery,
  usePosCustomerJobUpdateByIdMutation,
  useUpdateTemplateByIdMutation,
} from "../../Redux/API/atsSlice";
import DynamicTextInput from "../DynamicInputs/DynamicTextInput";
import { useLocation, useParams } from "react-router-dom";
import "./EditJob.css";
import usePrivilege from "../Privileges/Privileges";

function EditJob() {
  const { id } = useParams();
  const MovableInput = useRef();
  const isAuth = useSelector((state) => state.auth);
  const [customInputs, setCustomInput] = useState([]);
  const [EditJob] = usePosCustomerJobUpdateByIdMutation();
  const [Remove, setRemove] = useState(false);

  const privilege = usePrivilege("Job Update");
  const { data, refetch, isSuccess } = useGetCustomerJobUpdateByIdQuery({
    domain_name: isAuth?.user?.domain_name,
    Jobid: id,
  });
  useEffect(() => {
    refetch();
  }, []);
  useEffect(() => {
    if (data?.status) {
      setCustomInput(data?.message);
    }
  }, [data?.status]);

  function formatDateForMySQL(dateString) {
    if (!dateString || dateString.trim() === "") {
      return null; // Return NULL if the value is empty
    }

    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return null; // Return NULL if invalid date
    }

    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const day = String(date.getUTCDate()).padStart(2, "0");

    return `${year}-${month}-${day}`; // Return formatted YYYY-MM-DD
  }

  function convertToMySQLTimestamp(isoTimestamp) {
    const date = new Date(isoTimestamp);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }
  // updating job details function

  function ensureMySQLDateTime(dateTimeString) {
    if (!dateTimeString) return null; // Return null if no value is provided

    const parsedDate = new Date(dateTimeString);
    if (isNaN(parsedDate.getTime())) {
      return null; // Return null if the date is invalid
    }

    // Extract and format parts for MySQL DATETIME format
    const year = parsedDate.getFullYear();
    const month = String(parsedDate.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(parsedDate.getDate()).padStart(2, "0");
    const hours = String(parsedDate.getHours()).padStart(2, "0");
    const minutes = String(parsedDate.getMinutes()).padStart(2, "0");
    const seconds = String(parsedDate.getSeconds()).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  const handleListener = async () => {
    try {
      const data = {};

      customInputs &&
        customInputs.forEach((sections, i) => {
          sections?.fields.forEach((item, i) => {
            if (JSON.stringify(item) !== "{}") {
              if (item?.datatype === "boolean") {
                console.log("item", item?.valueOftheField);
                data[`${item?.ColumnName}`] =
                  item?.valueOftheField === "true"
                    ? true
                    : item?.valueOftheField === "false"
                    ? false
                    : item?.valueOftheField === true
                    ? true
                    : item?.valueOftheField === "null"
                    ? false
                    : false;
              } else if (item?.datatype === "int") {
                data[`${item?.ColumnName}`] = parseInt(
                  item?.valueOftheField === null ? 0 : item?.valueOftheField
                );
              } else if (item?.datatype === "timestamp") {
                data[`${item?.ColumnName}`] = convertToMySQLTimestamp(
                  item?.valueOftheField
                );
              } else if (item?.datatype === "date") {
                data[`${item?.ColumnName}`] = formatDateForMySQL(
                  item?.valueOftheField
                );
              } else {
                data[`${item?.ColumnName}`] = item?.valueOftheField;
              }
            }
          });
        });
      console.log("Data", data);
      try {
        const response = await EditJob({
          domain_name: isAuth?.user.domain_name,
          Jobid: id,
          data: data,
        });

        console.log("Res", response);
      } catch (err) {
        console.log(err);
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <div
        className="create-job-container"
        style={{ display: "flex", flexDirection: "row" }}
      >
        {/* <EditCreateJobLayout setCustomInput={setCustomInput} customInputs={customInputs} handleInput={handleInput} setHandleInput={setHandleInput} /> */}

        <div>
          <button
            className="edit-save-changes-button"
            onClick={handleListener}
            disabled={!privilege.includes(isAuth?.user?.user_id)} // Disable if user is unauthorized
            style={{
              cursor: privilege.includes(isAuth?.user?.user_id)
                ? "pointer"
                : "not-allowed",
              opacity: privilege.includes(isAuth?.user?.user_id) ? 1 : 0.6,
            }}
          >
            Update Job
          </button>
          <button className="edit-cancel-button">
            <a href="/ats/dashboard/?type=job_openings">Cancel</a>
          </button>
          <div>
            {customInputs.map((parent_item, parent_index) => (
              <div>
                <p
                  style={{
                    fontWeight: "bold",
                    fontSize: "15pt",
                    outline: "none",
                    border: "none",
                  }}
                >
                  {parent_item?.section}
                </p>
                <div>
                  {parent_item?.fields &&
                    parent_item?.fields.map((item, i) => (
                      <div className="bg-white p-4 rounded-lg shadow-md mb-3 flex items-center justify-between">
                        <DynamicTextInput
                          className="border-b-2 focus:outline-none flex-1 mr-2"
                          key={i}
                          parentInd={parent_index}
                          remove={Remove}
                          onChangeRemove={setRemove}
                          index={i}
                          {...item}
                          value={customInputs}
                          customInputs={customInputs}
                          onChangeCustomInput={setCustomInput}
                          onChangeValue={setCustomInput}
                        />
                      </div>
                    ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default EditJob;
