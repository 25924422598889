
import React, { useEffect,useState } from "react";
import "./OrbitSection.css"; // External CSS
 import ellips from '../../Assests/orbit-image.png';
 import logo from '../../Assests/ATS-logo.png'
 import icon1 from '../../Assests/icon1.png'
 import icon2 from '../../Assests/icon2.png'
 import icon3 from '../../Assests/icon3.png'
 import icon4 from '../../Assests/icon4.png'
 import icon5 from '../../Assests/icon5.png'
 import icon6 from '../../Assests/icon6.png'
 import icon7 from '../../Assests/icon7.png'
import { useGetAtsHomePageQuery } from "../../Redux/API/atsSlice";

const OrbitSection = () => {
  const[orbitData,setOrbitData] =useState([])
  const[orbitHeading,setOrbitHeading] = useState('')
  const[orbitButtons,setOrbitButtons] = useState([])

  const{data:orbit,isError,isSuccess} = useGetAtsHomePageQuery({})
 
useEffect(()=>{
  if(isSuccess && orbit?.message){
    const orbitSection =orbit.message.find(orbit=>orbit.section ==='cta');

    if(orbitSection){

      const heading = orbitSection.values.find((val) => val.type === "h5")?.content || "";
      setOrbitHeading(heading);

      // Extract buttons
      const buttons = orbitSection.values
        .filter((val) => val.type === "button")
        .map((btn) => ({
          content: btn.content,
          route: btn.route,
        }));
      setOrbitButtons(buttons);
      const rawContent =orbitSection.values.find(val=>val.type==="array")?.content;
     
      try {
        if (typeof rawContent === "string") {
        
          const matches = rawContent.match(/\[\s*\{[\s\S]*\}\s*\]/g); 
          if (matches && matches.length > 0) {
            const validJSON = matches[matches.length - 1]; 
            const parsedContent = JSON.parse(validJSON);
            setOrbitData(parsedContent);
          } else {
            console.error("No valid JSON array found in content");
            setOrbitData([]);
          }
        } else {
          setOrbitData([]); 
        }
      } catch (error) {
        console.error("Error parsing JSON:", error, rawContent);
        setOrbitData([]); 
      }
     
    }
  }

},[isSuccess,orbit])



const centerIcon = orbitData.find(item => item["center-icon"])?.["center-icon"] || null;
const topLeft = orbitData.find(item => item["top-left"])?.["top-left"] || null;
const middleLeft = orbitData.find(item => item["middle-left"])?.["middle-left"] || null;
const bottomLeft = orbitData.find(item => item["bottom-left"])?.["bottom-left"] || null;
const bottomLeft1 = orbitData.find(item => item["bottom-left1"])?.["bottom-left1"] || null;
const middleRight = orbitData.find(item => item["middle-right"])?.["middle-right"] || null;
const middleRight1 = orbitData.find(item => item["middle-right1"])?.["middle-right1"] || null;
const topRight = orbitData.find(item => item["top-right"])?.["top-right"] || null;
const bottomRight = orbitData.find(item => item["bottom-right"])?.["bottom-right"] || null;


  return (
    <div className="hero-container">
      <h1>
      <span className="highlight">{orbitHeading.split(" ")[0]}</span> {orbitHeading.split(" ").slice(1).join(" ")}
      </h1>
      <div className="ellips-buttons">
    {orbitButtons.map((btn, index) => (
      <a key={index} href={btn.route}>
        <button className={index === 0 ? "elips-get-started" : "elips-see-demo"}>
          {btn.content}
        </button>
      </a>
    ))}
  </div>

      {/* Elliptical Section */}
      <div className="ellipse-container">
        <img src={ellips} alt="Ellipse Background" className="ellipse-bg" />
        <img src={logo} alt="ATS Logo" className="ats-logo" />

        {/* Icons with absolute positioning */}
        <img src={icon1} alt="Icon1" className="icon icon1" />
        <img src={icon2} alt="Icon2" className="icon icon2" />
       <img src={icon3} alt="Icon3" className="icon icon3" />
       <img src={icon4} alt="Icon4" className="icon icon4" />
       <img src={icon5} alt="Icon5" className="icon icon5" />
        <img src={icon6} alt="Icon6" className="icon icon6" />
        <img src={icon7} alt="Icon7" className="icon icon7" />
      </div>
    </div>
  );
};

export default OrbitSection;

