// import React from 'react'
// import Navbar from '../Navbar/Navbar';
// import Footer from '../Footer/Footer';
// import AtsHeader from '../AtsHeader/AtsHeader';
// import {useLocation} from 'react-router-dom' 
// import HomePage from '../HomePage/HomePage';
// import ContactUs from '../ContactUs/ContactUs';
// import Features from '../Features/Features';
// import HowItWorks from '../AtsHowItWorks/HowItWorks';
// import CarouselSection from '../AtsCarousel/Carousel';
// import OrbitSection from '../AtsOrbitSection/OrbitSection';
// import AtsTestimonial from '../AtsTestimonial/AtsTestimonial';
// import AtsHero from '../AtsHeroSection/AtsHero';

// const AtsHomePage = () => {
//   const location = useLocation()
//   return (
//     <>
//     <div>
//     {/* <Navbar/> */}
//     <AtsHeader/>
//     <AtsHero/>
//     {/* <HomePage/> */}
//     <Features/>
//     <CarouselSection/>
//     <HowItWorks/>
//     <AtsTestimonial/>
//     <OrbitSection/>
//       <ContactUs/>
//     <Footer/>
//     </div>
//     </>
//   )
// }

// export default AtsHomePage

import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { applyTheme } from '../../Common/theme/theme'; // Import theme function
import AtsHeader from '../AtsHeader/AtsHeader';
import AtsHero from '../AtsHeroSection/AtsHero';
import Features from '../Features/Features';
import CarouselSection from '../AtsCarousel/Carousel';
import HowItWorks from '../AtsHowItWorks/HowItWorks';
import AtsTestimonial from '../AtsTestimonial/AtsTestimonial';
import OrbitSection from '../AtsOrbitSection/OrbitSection';
import ContactUs from '../ContactUs/ContactUs';
import Footer from '../Footer/Footer';

const AtsHomePage = () => {

  useEffect(() => {
    applyTheme(); // Apply theme colors on component mount
  }, []);

  const styles = {
    container: {
      width: '100%',
      margin: '0 auto',
      padding: '0',
      backgroundColor: 'var(--fullWhite)', // Using CSS variable
      // backgroundColor: 'purple', // Using CSS variable
      // border:'1px solid red'
    },
    section: {
      // padding: '40px 20px',
      textAlign: 'center',
       border:'5px solid purple'

    },
    header: {
      backgroundColor: 'var(--primaryColor)', // Theme primary color
      color: 'var(--fullWhite)',
      padding: '15px',
      fontSize: '24px',
      fontWeight: 'bold',
    },
    footer: {
      backgroundColor: 'var(--fullBlack)', // Theme black color
      color: 'var(--fullWhite)',
      textAlign: 'center',
      border:'10px solid black'
    },
  };

  return (
    <div style={styles.container}>
      <AtsHeader />
      <AtsHero />
        <Features />
        <CarouselSection />
        <HowItWorks />
        <AtsTestimonial />
        <OrbitSection />
        <ContactUs />
      <Footer style={styles.footer} />
    </div>
  );
};

export default AtsHomePage;
