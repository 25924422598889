import React  from "react";
import { ColorPicker, useColor } from "react-color-palette";
import "react-color-palette/css";
import { useEffect } from "react";

function ColorPalette({ state, type, onchange }) {
    // useColor is a hook that helps manage color state.
    const [color, setColor] = useColor("hex", state[type]); 



    return (
        <div style={{ width: "15vw", height: "20vh", minHeight: "10px", maxHeight: "10px",zIndex:"2",backgroundColor:'red'}}>
            <ColorPicker
                disabled={false}
                hideInput={false}
                hideAlpha={true}
                // height={"40vh"}
                color={color}
                onChange={(newColor) => {
                    setColor(newColor);  
                    onchange((prevState) => ({
                        ...prevState,
                        [type]: newColor.hex,  
                    }));
                }}
            />
        </div>
    );
}

export default ColorPalette;
