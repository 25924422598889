import { useEffect, useState } from "react";
import { useGetAtsHomePageQuery } from "../../Redux/API/atsSlice";
import "./AtsHeader.css";
import { BASE_URL } from "../../Redux/API/apiSlice";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../Redux/API/authSlice";

const AtsHeader = () => {
  const { data: Atsheader, isSuccess } = useGetAtsHomePageQuery();
  const dispatch = useDispatch();
  const isAuth = useSelector((state) => state.auth);

  // State for storing header values
  const [logo, setLogo] = useState(null);
  const [navMenu, setNavMenu] = useState([]);
  const [rightButtons, setRightButtons] = useState([]);
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    if (isSuccess && Atsheader?.message) {
      const headerSection = Atsheader.message.find(
        (section) => section.section === "header"
      );

      if (headerSection) {
        const ATS = headerSection.values || [];

        // Left and right items
        const leftItems = ATS.filter((item) => item.position === "left");
        const rightItems = ATS.filter((item) => item.position === "right");

        // Set logo
        const logoItem = leftItems.find((item) => item.type === "image")?.content;
        setLogo(logoItem);

        // Parse navigation menu
        const navMenuRaw = ATS.find((item) => item.type === "array");
        try {
          setNavMenu(
            navMenuRaw
              ? {
                position: navMenuRaw?.position,
                content: JSON.parse(navMenuRaw?.content),
              }
              : {
                position: "",
                content: [],
              }
          );
        } catch (error) {
          console.error("Error parsing navigation menu:", error);
        }

        // Set right buttons
        setRightButtons(rightItems.filter((item) => item.type === "button"));
      }
    }
  }, [isSuccess, Atsheader]);

  return (
    <header className="ATS-header">
      <div className="left-container">
        {/* Logo */}
        {logo && <img src={`${BASE_URL}${logo}`} alt="Logo" className="logo" />}

        {/* Hamburger Menu (Only in Mobile) */}
        <div className="hamburger" onClick={() => setMenuOpen(!menuOpen)}>
          <div className={`bar ${menuOpen ? "open" : ""}`}></div>
          <div className={`bar ${menuOpen ? "open" : ""}`}></div>
          <div className={`bar ${menuOpen ? "open" : ""}`}></div>
        </div>

        {/* Navigation Menu */}
        <nav className={`nav-menu ${menuOpen ? "active" : ""}`}
          style={{ justifyContent: navMenu?.position === "left" ? "flex-start" : "flex-end" }}>
          <ul>
            {navMenu?.content?.map((item, index) => (
              <li key={index}>
                <a href={item.route} onClick={() => setMenuOpen(false)}>
                  {item.name}
                </a>
              </li>
            ))}

            {/* Show Access Button Inside Mobile Menu */}
            {menuOpen && isAuth?.isAuthenticated &&
              rightButtons
                .filter((button) => button.content === "Access Our Product")
                .map((button, index) => (
                  <a key={index} href={button.route} className="button">
                    {button.content}
                  </a>
                ))}


            {menuOpen && !isAuth?.isAuthenticated &&
              rightButtons
                .filter((button) => button.content === "Login")
                .map((button, index) => (
                  <a key={index} href={button.route} className="button">
                    {button.content}
                  </a>
                ))}
          </ul>
        </nav>

      </div>

      {/* Right Side Buttons (Only in Desktop) */}
      <div className="right-container">
        {isAuth?.isAuthenticated
          ? rightButtons
            .filter((button) => button.content === "Access Our Product")
            .map((button, index) => (
              <a key={index} href={button.route} className="button">
                {button.content}
              </a>
            ))
          : rightButtons
            .filter((button) => button.content === "Login")
            .map((button, index) => (
              <a key={index} href={button.route} className="button">
                {button.content}
              </a>
            ))}
      </div>
    </header>
  );
};

export default AtsHeader;
