// import React from 'react';
// import Accordion from './Accordion';
// import "./CandidateInfoDetail.css";
// import { BASE_URL } from '../../../../Redux/API/apiSlice';

// const CandidateInfoDetail = ({ candidateAllInfo }) => {
//   if (!candidateAllInfo?.status || !candidateAllInfo?.message) {
//     return <p>No candidate information available.</p>;
//   }
//   const formatDate = (date) => {
//     const d = new Date(date);
//     const day = String(d.getDate()).padStart(2, '0');
//     const month = String(d.getMonth() + 1).padStart(2, '0'); 
//     const year = d.getFullYear();
//     return `${day}-${month}-${year}`;
//   };
//   return (
//     <div>
//       {candidateAllInfo.message.map((section, sectionIndex) => (
//         <Accordion key={sectionIndex} title={section.section}>
//           <div className='candidata-info-detail-container'>
//             {section.section === "Skills" ? (
//               // If the section is "Skills", join skill values with a comma
//               <div key={sectionIndex} className='candidata-info-detail-subcontainer'>
//                 <span className='candidata-info-detail-value'>
//                   {section.values.map((value) => value.Skill).join(", ")}
//                 </span>
//               </div>
//             ) : (
//               // Normal rendering for other sections
//               section.values.map((value, valueIndex) => {
//                 const key = Object.keys(value)[0];
//                 const val = value[key];
//                 console.log("type",typeof val)
//                 console.log("valuess",section.values)
//                 return (
//                   <div key={valueIndex} className='candidata-info-detail-subcontainer'>
//                     <span className='candidata-info-detail-key'>{key === "Skill" ? "" : key}</span>
//                     {typeof val === "string" && (val.startsWith("http") || val.startsWith("public")) ? (
//                       <a href={val.startsWith("http") ? val : `${BASE_URL}${val}`} className='candidata-info-detail-value'>{val.length>25?`${val.substring(0,25)}...`:val}</a>
//                     ) : (
//                       <span className='candidata-info-detail-value'>{val ? val : "-"}</span>
//                     )}
//                   </div>
//                 );
//               })
//             )}
//           </div>
//         </Accordion>
//       ))}
//     </div>
//   );
// };

// export default CandidateInfoDetail;






import React, { useState } from 'react';
import Accordion from './Accordion';
import "./CandidateInfoDetail.css";
import { BASE_URL } from '../../../../Redux/API/apiSlice';

const CandidateInfoDetail = ({ candidateAllInfo }) => {
  const [openIndex, setOpenIndex] = useState(0); 

    const formatDate = (date) => {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, '0');
    const month = String(d.getMonth() + 1).padStart(2, '0'); 
    const year = d.getFullYear();
    function convertDate(dateString) {
      const months = [
          "January", "February", "March", "April", "May", "June", 
          "July", "August", "September", "October", "November", "December"
      ];
      
      const [day, month, year] = dateString.split("-");
      return `${parseInt(day)} ${months[parseInt(month) - 1]} ${year}`;
  }
    return convertDate(`${day}-${month}-${year}`);
  };
  if (!candidateAllInfo?.status || !candidateAllInfo?.message) {
    return <p>No candidate information available.</p>;
  }

  return (
    <div>
      {candidateAllInfo.message.map((section, sectionIndex) => (
        <Accordion key={sectionIndex} title={section.section}
       
       
          isOpen={sectionIndex === openIndex} 
          onClick={() => setOpenIndex(sectionIndex)} 
        >
          <div className='candidata-info-detail-container'>
            {section.section === "Skills" ? (
              <div key={sectionIndex} className='candidata-info-detail-subcontainer'>
                <span className='candidata-info-detail-value'>
                  {section.values.map((value) => value.Skill).join(", ")}
                </span>
              </div>
            ) : (
              section.values.map((value, valueIndex) => {
                const key = Object.keys(value)[0];
                const val = value[key];
                const type = value.typeOftheField;
                console.log(type)
                const isLink = typeof val === "string" && (val.startsWith("http") || val.startsWith("public"));
                const fullUrl = isLink ? (val.startsWith("http") ? val : `${BASE_URL}${val}`) : "";
                const shortUrl = fullUrl.length > 15 ? `${fullUrl.substring(0, 15)}...` : fullUrl;

                return (
                  <div key={valueIndex} className='candidata-info-detail-subcontainer'>
                    <span className='candidata-info-detail-key'>{key === "Skill" ? "" : key}</span>
                    {isLink ? (
                      <div 
                      className="tooltip-container"
                      >
                        <a 
                          href={fullUrl} 
                          className='candidata-info-detail-value' 
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {shortUrl}
                        </a>
                        <span className="tooltip-text">{fullUrl}</span>
                      </div>
                    ) : (
                      <span className='candidata-info-detail-value'>
                        {/* {val ? val : "-"} */}
                   
                        {type === "date" ? formatDate(val) : val ? val : "-"}
                        </span>
                    )}
                  </div>
                );
              })
            )}
          </div>
        </Accordion>
      ))}
    </div>
  );
};

export default CandidateInfoDetail;
