import React from 'react'
import { Toaster ,toast} from 'react-hot-toast'

//Dynamic Common Toast Provider
const ToastProvider = () => {
  return (
    <Toaster
    position='top-right'
    toastOptions={{
        style:{
            border:'1px solid #4caf50',
            padding:'16px',
            color:'#4caf50'
        },
        success:{
            style:{
                border:'1px solid #28a745',
                backgroundColor:'#e9f7f0',
                color:'#28a745'
            }
        },
        error:{
            style:{
                border:'1px solid #f44336',
                backgroundColor:'#fddede',
                color:'#f44336'
            }
        }
        
    }}
    />
  )
};

// export const showToast={
//     success: (message) => toast.success(`👏${message}`),
//     error: (message) => toast.error(`😣${message}`),
//     info: (message) => toast(message),
// }

export const showToast = (status,message) =>{
    if(status){
        toast.success(`${message}`)
    }else{
        toast.error(`${message}`)
    }
}

export default ToastProvider;
