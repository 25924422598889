import React from 'react'

const CandidateInterview = () => {
  return (
    <div>
        <h1>Hello</h1>
      
    </div>
  )
}

export default CandidateInterview
