import React, { useState, useEffect, useRef } from "react";
import "./HowItWorks.css";
import { useGetAtsHomePageQuery } from "../../Redux/API/atsSlice";
import { BASE_URL } from "../../Redux/API/apiSlice";
import { AiOutlineThunderbolt } from "react-icons/ai";

const HowItWorks = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const sectionRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const [worksData, setWorksData] = useState([]);

  const { data: works, isSuccess } = useGetAtsHomePageQuery({});

  useEffect(() => {
    if (isSuccess && works?.message) {
      const featuresSection = works.message.find(
        (section) => section.section === "effortless_hiring"
      );

      if (featuresSection) {
        const rawContent = featuresSection.values.find(
          (val) => val.type === "array"
        )?.content;

        try {
          if (typeof rawContent === "string") {
            const matches = rawContent.match(/\[\s*\{[\s\S]*\}\s*\]/g);
            if (matches && matches.length > 0) {
              const validJSON = matches[matches.length - 1];
              const parsedContent = JSON.parse(validJSON);
              setWorksData(parsedContent);
            }
          }
        } catch (error) {
          console.error("Error parsing JSON:", error, rawContent);
          setWorksData([]);
        }
      }
    }
  }, [isSuccess, works]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      { threshold: 0.5 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (isVisible && worksData.length > 0 && currentStep === 0) {
      let step = 1;
      const interval = setInterval(() => {
        setCurrentStep(step);
        step++;
        if (step === worksData.length) clearInterval(interval);
      }, 2000);
    }
  }, [isVisible, worksData]);

  return (
    <div ref={sectionRef} className="steps-container">
      <h1>
        <span className="highlight">How</span> it Works
      </h1>
      <button className="effortless-hiring-tag">
        <AiOutlineThunderbolt className="effortless-hiring-btn" />
        Effortless Hiring
      </button>

      <div className="steps">
        {worksData.map((step, index) => (
          <div
            key={index}
            className={`step-card ${index <= currentStep ? "active" : ""}`}
          >
            <div className="step-header">
              <img
                src={`${BASE_URL}${step.icon}`}
                alt={step.title}
                className="step-icon"
              />
              <span className="step-number">Step {index + 1}</span>
            </div>
            <h3 className="step-title">{step.title}</h3>
            <p className="step-description">{step.description}</p>
          </div>
        ))}
      </div>

      {/* Progress Bar */}
      <div className="progress-bar">
        {worksData.map((_, index) => (
          <div
            key={index}
            className={`progress-step ${index <= currentStep ? "filled" : ""}`}
          >
            <span className="step-circle">{index + 1}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HowItWorks;
