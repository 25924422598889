import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useCreateUserMutation, useGetHierachyQuery, useGetRoleQuery, useGetUsersQuery } from "../../../../Redux/API/atsSlice";
import { useGetCountryListQuery } from "../../../../Redux/API/homeSlice";


const CreateUser = () =>{
    const [iscreateUser,setisCreateUser] = useState(false)
     const isAuth = useSelector((state) => state.auth);
    const [form,setForm] = useState({
        domain_name:'',firstname:'', lastname:'', email_id:'', password:'', created_by:'',role_name:'',role_id:'',reportingManager:[],dottedLineManager:[],phonecode:"",mobile_number:""
    })


      const {
            data: hierachy, isSuccess: hierachySuccess
        } = useGetHierachyQuery({
            domain_name: isAuth?.user?.domain_name,
        })

    const [createUser] = useCreateUserMutation()
    const handleSubmit = async ()=>{
        try{
            const response = await createUser({
                domain_name:isAuth?.user?.domain_name,firstname:form?.firstname, lastname:form?.lastname, email_id:form.email_id, password:form.password, created_by:isAuth?.user?.firstname,role_name:form?.role_name,role_id:form?.role_id,phonecode:form.phonecode,mobilenumber:form?.mobile_number,
                reporting_manager:form.reportingManager?.length>0?form.reportingManager[0]?.h_id:"",dottedlinemanager:form.dottedLineManager?.length>0?form?.dottedLineManager[0]?.value:""
            })
        
        }catch(err){

        }
    }

    const {
        data:CountryList ,isSuccess:CountrySuccess, refetch:CountryRefetch
    } = useGetCountryListQuery()
    const [searchText,setSearchText] = useState("")
    const [searchText2,setsearchText2] = useState("")
    const [SearchedManager,setSearchManager] = useState([])
    const [SearchDottedLine,setSearchDottedLine] = useState([])
     const {
            data:AllUser, isSuccess:AlluserSuccess,refetch:AllUserRefetch
        } = useGetUsersQuery({
            domain_name: isAuth?.user?.domain_name,
        })
    

    useEffect(()=>{
        if(searchText.length===0){
            setSearchManager([])
        }else{
            setSearchManager(hierachy?.message?.filter((item,i)=>item?.label?.toLowerCase()?.replaceAll(" ","")?.includes(searchText?.toLowerCase().replaceAll(" ",""))))
        }      
    },[searchText])

    useEffect(()=>{
        if(searchText2.length===0){
            setSearchDottedLine([])
        }else{
            setSearchDottedLine(AllUser?.message?.filter((item,i)=>item?.label?.toLowerCase()?.replaceAll(" ","")?.includes(searchText2?.toLowerCase().replaceAll(" ",""))))
        }
    },[searchText2])

     const [roleData, setroleData] = useState()
        const {
            data, refetch, isSuccess
        } = useGetRoleQuery({
            domain_name: isAuth?.user?.domain_name
        })
    
        useEffect(() => {
            if (data?.status) {
                setroleData(data?.message || [])
            }
        }, [isSuccess])

        useEffect(() => {
            if (roleData?.length > 0 && form.role_name) {
                const foundRole = roleData.find((item) => item.role_name === form.role_name);
                if (foundRole?.role_id !== form.role_id) { // Prevent unnecessary state updates
                    setForm((prev) => ({ ...prev, role_id: foundRole?.role_id || "" }));
                }
            }
        }, [roleData, form.role_name]);
    return (
        <>
        <div>
            {AllUser && AllUser?.message?.map((item,i)=>{
                return(
                    <p>{item?.label} ({item?.role_name})</p>
                )
            })}
            <button onClick={()=>setisCreateUser(!iscreateUser)}>Create User</button>
        </div>
        {
            iscreateUser &&  <div>
            <input type="text" placeholder="Enter the FirstName" value={form?.firstname} onChange={(e)=>setForm({...form,firstname:e.target.value})}></input>
            <input type="text" placeholder="Enter the lastname" value={form.lastname} onChange={(e)=>setForm({...form,lastname:e.target.value})}/>
            <select value={form?.role_name} onChange={(e)=>{setForm({...form,role_name:e.target.value})}}>
                {roleData && roleData.map((item,i)=>{
                    return (
                        <option value={item?.role_name}>{item?.role_name}</option>
                    )
                })}
            </select>
            <input type="text" placeholder="Enter the Role ID" value={form.role_id} readOnly={true}></input>
            <input type="email" placeholder="Enter the Email ID" value={form.email_id} onChange={(e)=>setForm({...form,email_id:e.target.value})}/>
            <input type="text" placeholder="Enter the Password" value={form.password} onChange={(e)=>setForm({...form,password:e.target.value})}/>
            <div>
            <select value={form.phonecode} onChange={(e)=>setForm({...form,phonecode:e.target.value})}>
                {CountryList?.status && CountryList?.message.map((item,i)=>{
                    return(
                        <option value={item?.phonecode}>{item?.name} {`\t`} {item?.phonecode}</option>
                    )
                })}
            </select>
            <input type="text" value={form.mobile_number} onChange={(e)=>setForm({...form,mobile_number:e.target.value})} placeholder="Enter the Contact Number" />
            </div>
            <>
            <input type="text" placeholder="Search for Reporting Manager"  value={form.reportingManager?.length>0?form.reportingManager[0]?.label:searchText} onChange={(e)=>{
                if(form.reportingManager.length===0){
                    setSearchText(e.target.value)
                }
            }}/>
            {
                SearchedManager && SearchedManager?.map((item,i)=>{
                    return(
                        <div onClick={(e)=>{
                            if(form.reportingManager.length==0){
                                setForm({...form,reportingManager:[item]})
                            }
                            }}>
                            <button onClick={(e)=>{
                                if(form.reportingManager.length>0){
                                    setForm({...form,reportingManager:[]})
                                }
                            }} >Cancel</button>
                        <p>{item?.label}</p>
                        <p>{item?.value}</p>
                        </div>
                    )
                })
            }
            </>
            <input type="text"  placeholder="Dotted Line Manager (Optional)" value={form.dottedLineManager?.length>0?form.dottedLineManager[0]?.label:searchText2} onChange={(e)=>{
                if(form.dottedLineManager.length===0){
                    setsearchText2(e.target.value)
                }
            }}/>
            {
               SearchDottedLine && SearchDottedLine?.map((item,i)=>{
                return(
                    <div onClick={(e)=>{
                        if(form.dottedLineManager.length==0){
                            setForm({...form,dottedLineManager:[item]})
                        }
                        }}>
                        <button onClick={(e)=>{
                            if(form.dottedLineManager.length>0){
                                setForm({...form,dottedLineManager:[]})
                            }
                        }} >Cancel</button>
                    <p>{item?.label}</p>
                    <p>{item?.value}</p>
                    </div>
                )
            })  
            }
            <button onClick={handleSubmit}>Submit</button>
        </div>
        }
       
        </>
    )
}

export default CreateUser