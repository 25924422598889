import React, { useEffect, useState } from "react";
import { useCreateGroupsMutation, useGetGroupsQuery, useGetUsersQuery } from "../../../../Redux/API/atsSlice";
import { useSelector } from "react-redux";
import Select from "react-select";

const CreateGroups = () => {
    const isAuth = useSelector((state) => state.auth);
    const [AllGroups,setAllGroups] = useState()
    const [groupData, setGroupData] = useState({
        group_name: "",
        group_head: "",
        role_id: "",
        role_name: "",
        user_id: "",
        item: [],
    });

    const [dropdown, setDropdown] = useState([]);
    const [multiSelectData, setMultiSelectData] = useState([]);

    const {
        data:Groups,isSuccess:GroupSuccess
    } = useGetGroupsQuery({
        domain_name: isAuth?.user?.domain_name,
    })

    useEffect(()=>{
        if(Groups?.status){
            setAllGroups(Groups?.message || [])
        }
    },[GroupSuccess])
    
    const { data, isSuccess } = useGetUsersQuery({
        domain_name: isAuth?.user?.domain_name,
    });
    const [createGroup] = useCreateGroupsMutation()
    const handleSubmit = async () =>{
        try{
            const response = await createGroup({domain_name:isAuth?.user?.domain_name,data:groupData,user:`${isAuth?.user?.firstname} ${isAuth?.user?.lastname}`})
            console.log(response)
        }catch(err){

        }
    }
    // Populate dropdown when data is fetched
    useEffect(() => {
        if (isSuccess && data?.status) {
            setDropdown(data?.message || []);
        }
    }, [isSuccess, data]);

    // Update selected user's details & filter out from multi-select options
    useEffect(() => {
        if (dropdown.length > 0 && groupData.user_id) {
            const selectedUser = dropdown.find((item) => item?.value === groupData?.user_id);
            if (selectedUser) {
                setGroupData((prev) => ({
                    ...prev,
                    group_head: selectedUser?.label,
                    role_name: selectedUser?.role_name,
                    role_id: selectedUser?.role_id,
                }));

                setMultiSelectData(dropdown.filter((item) => item?.value !== groupData?.user_id));
            }
        }
    }, [groupData.user_id, dropdown]);

    // console.log(groupData)

    return (
        <>
            {/* Group Name Input */}
            <input
                type="text"
                placeholder="Enter the Group Name"
                value={groupData.group_name}
                onChange={(e) => setGroupData((prev) => ({ ...prev, group_name: e.target.value }))}
            />

            {/* Group Head Dropdown (Shown only when Group Name is entered) */}
            {/* {groupData.group_name.length > 0 && ( */}
                <select style={groupData.group_name.length>0?{display:'flex'}:{display:'none'}}
                    value={groupData?.user_id}
                    onChange={(e) => setGroupData((prev) => ({ ...prev, user_id: e.target.value }))}
                >
                    <option value="">Select Group Head</option>
                    {dropdown.map((item, i) => (
                        <option key={i} value={item?.value}>
                            {item?.label} ({item?.role_name})
                        </option>
                    ))}
                </select>
            {/* // )} */}

            {/* Multi-Select for Additional Users (Shown only when Group Head is selected) */}
            {groupData?.group_head.length > 0 && (
                <div style={{ display: "block", marginTop: "10px" }}>
                    <Select
                        onChange={(selected) => setGroupData((prev) => ({ ...prev, item: selected }))}
                        isMulti
                        options={multiSelectData}
                    />
                </div>
            )}
            <button onClick={handleSubmit}>Submit</button>

            <div>
                {AllGroups && AllGroups.map((item,i)=>{
                    return (
                        <>
                        <h3>Team Name : {item?.group_name}</h3>
                        <p>Team Lead : {item?.group_lead} ({item?.role_name})</p>
                        <ul>
                            {item?.team && item?.team?.map((ele,i)=>{
                                return(
                                    <li>{ele?.name} ({ele?.role_name})</li>
                                )
                            })}
                        </ul>
                        </>
                    )
                })}
            </div>
        </>
    );
};

export default CreateGroups;
