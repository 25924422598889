export const TextInput = [
    {
        typeOftheField: "textInput",
        typeInput: "text",
        placeholder: 'Enter your Input Placeholder',
        required: false,
        labeleditable: true,
        inputEditable: false,
        defaultValue: null,
        valueOftheField: "",
        label: "Text Input",
        setting: "",
        ColumnName:'',
        dataType:"varchar(255)",
        index_of:""
    }
]

export const EmailInput = [
    {
        typeOftheField: "emailInput",
        typeInput: "email",
        placeholder: 'Enter your Email Placeholder',
        required: false,
        labeleditable: true,
        inputEditable: false,
        defaultValue: null,
        valueOftheField: "",
        label: "Email Input",
        setting: "",
        ColumnName:'',
        dataType:"varchar(255)",
        index_of:""
    }
]

export const DateInput = [
    {
        typeOftheField: "dateInput",
        typeInput: "date",
        placeholder: 'Enter your Date Placeholder',
        required: false,
        labeleditable: true,
        inputEditable: false,
        defaultValue: null,
        valueOftheField: null,
        label: "Date Input",
        setting: "",
        ColumnName:'',
        dataType:"date",
         index_of:""
    },
]


export const FileInput = [
    {
        typeOftheField: "fileInput",
        typeInput: "file",
        placeholder: 'Enter your Fileplaceholder',
        required: false,
        labeleditable: true,
        inputEditable: false,
        defaultValue: null,
        valueOftheField: null,
        label: "File Input",
        setting: "",
        ColumnName:'',
        dataType:"longtext",
         index_of:""
    }
]

export const DropDownInput = [
    {
        typeOftheField: "dropdownInput",
        typeInput: "dropdown",
        placeholder: 'Enter your Dropdown Placeholder',
        required: false,
        labeleditable: true,
        inputEditable: false,
        defaultValue: null,
        valueOftheField: null,
        dataForDropdown: 1,
        label: "DropDown Input",
        setting: "",
        ColumnName:'',
        dataType:"varchar(255)",
         index_of:""
    }
]


export const NumberInput = [
    {
        typeOftheField: "numberInput",
        typeInput: "number",
        placeholder: 'Enter your Number Placeholder',
        required: false,
        labeleditable: true,
        inputEditable: false,
        defaultValue: null,
        valueOftheField: null,
        label: "Number Input",
        setting: "",
        ColumnName:'',
        dataType:"int",
         index_of:""
    }
]

export const MultiSelectInput = [
    {
        typeOftheField: "multiselectInput",
        typeInput: "multiselect",
        placeholder: 'Enter your Multiselect Placeholder',
        required: false,
        labeleditable: true,
        inputEditable: false,
        // typeOftheField:"text",
        defaultValue: null,
        valueOftheField: null,
        dataForDropdown: 1
        // [
        //     // {
        //     //     value: 0,
        //     //     label: "Option1"
        //     // }, {
        //     //     value: 1,
        //     //     label: "Option2"
        //     // },
        //     {
        //         id:1,
        //         label:"Hello"
        //     },
        //     {
        //         value:2,
        //         label:"Hello2"
        //     }
        // ]
        ,
        label: "MultiSelect Input",
        setting: "",
        ColumnName:'',
        dataType:"varchar(255)",
         index_of:""
    }
]

export const EditorInput = [
    {
        typeOftheField: "editorInput",
        typeInput: "editor",
        placeholder: 'Enter your Editor Placeholder',
        required: false,
        labeleditable: true,
        inputEditable: false,
        defaultValue: null,
        valueOftheField: null,
        label: `Editor Input`,
        setting: "",
        ColumnName:'',
        dataType:"longtext",
         index_of:""
    }
]


export const DateAndTimeInput = [
    {
        typeOftheField:"timeandDateInput",
        typeInput:"datetime-local",
        placeholder:'Enter your DateAndTime Placeholder',
        required:false,
        labeleditable:true,
        inputEditable:false,
        defaultValue:null,
        valueOftheField:null,
        label:'Date And Time Input',
        setting:"",
        ColumnName:'',
        dataType:"timestamp",
         index_of:""
    }
]


export const DecimalInput = [
    {
        typeOftheField:"decimalInput",
        typeInput:"number",
        placeholder:'Enter your Decimal Placeholder',
        required:false,
        labeleditable:true,
        inputEditable:false,
        defaultValue:null,
        valueOftheField:null,
        label:"Decimal Input",
        setting:"",
        ColumnName:'',
        dataType:"varchar(255)",
         index_of:""
    }
]

export const RadioInput = [
    {
        typeOftheField:"radioInput",
        typeInput:"radio",
        placeholder:'Enter your Radio Placeholder',
        required:false,
        labeleditable:true,
        inputEditable:false,
        defaultValue:null,
        valueOftheField:null,
        dataForDropdown:1,
        label:"Radio Input",
        setting:"",
        ColumnName:'',
        dataType:"varchar(255)",
         index_of:""
    }
]

export const CheckBoxInput = [
    {
        typeOftheField:"checkBoxInput",
        typeInput:"checkbox",
        placeholder:'Enter your Radio Placeholder',
        required:false,
        labeleditable:true,
        inputEditable:false,
        defaultValue:null,
        valueOftheField:false,
        dataForDropdown:1,
        label:"CheckBox Input",
        setting:"",
        ColumnName:'',
        dataType:"boolean",
        index_of:""
    }
]

export const TimeInput = [
    {
        typeOftheField:"timeInput",
        typeInput:"time",
        placeholder:'Enter your Time',
        required:false,
        labeleditable:true,
        inputEditable:false,
        defaultValue:null,
        valueOftheField:false,
        dataForDropdown:1,
        label:"Time Input",
        setting:"",
        ColumnName:'',
        dataType:"time",
        index_of:""
    }
]