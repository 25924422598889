import React from "react";
import "./CustomInput.css";
// common reusable input field component
const CustomInput = ({ type, value, onChange, label, required, name, onBlur, error ,autoComplete}) => {
  return (
    <div className="input-group">
      <input
        type={type}
        value={value}
        onChange={onChange}
        className="input"
        placeholder=" "
        required={required}
        name={name}
        onBlur={onBlur}
        autoComplete={autoComplete}
      />
      <label className="label">{label}</label>
      {error && <div className="error-message">{error}</div>}
    </div>
  );
};

export default CustomInput;