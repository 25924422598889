import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import DynamicTextInput from "../DynamicInputs/DynamicTextInput";
import {
  useGetDepartmentByIdQuery,
  usePostCustomerDeptUpdateByIdMutation,
} from "../../Redux/API/atsSlice";
import { showToast } from "../ToastContainer/ToastProvider";


function EditDepartments() {
  const { dept_id } = useParams();
  const isAuth = useSelector((state) => state.auth);
  const [customInputs, setCustomInput] = useState([]);
  const [Remove, setRemove] = useState(false);
  const navigate=useNavigate();

  // Fetch department data
  const { data, isSuccess } = useGetDepartmentByIdQuery({
    domain_name: isAuth?.user?.domain_name,
    Deptid: dept_id || "null",
  });


  //update department query 
  const [EditDepartments] = usePostCustomerDeptUpdateByIdMutation();

  useEffect(() => {
    if (isSuccess && data?.status) {
      setCustomInput(data?.message || []);
    }
  }, [isSuccess, data]);

//  console.log("dataaaaa", data);
 function formatDateForMySQL(dateString) {
  if (!dateString || dateString.trim() === '') {
      return null; // Return NULL if the value is empty
  }

  const date = new Date(dateString);
  if (isNaN(date.getTime())) {
      return null; // Return NULL if invalid date
  }

  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const day = String(date.getUTCDate()).padStart(2, '0');

  return `${year}-${month}-${day}`; // Return formatted YYYY-MM-DD
}

  function ensureMySQLDateTime(dateTimeString) {
    if (!dateTimeString) return null;
    
    const parsedDate = new Date(dateTimeString);
    if (isNaN(parsedDate.getTime())) {
        return null; 
    }
    
    // Extract and format parts for MySQL DATETIME format
    const year = parsedDate.getFullYear();
    const month = String(parsedDate.getMonth() + 1).padStart(2, '0');
    const day = String(parsedDate.getDate()).padStart(2, '0');
    const hours = String(parsedDate.getHours()).padStart(2, '0');
    const minutes = String(parsedDate.getMinutes()).padStart(2, '0');
    const seconds = String(parsedDate.getSeconds()).padStart(2, '0');
    
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }

  const handleSaveChanges = async () => {
        try {
          const data = {

          }
          customInputs && customInputs.forEach((section, i) => {
              section?.fields.forEach((item) => {
                  if (item?.datatype === "boolean") {
                      data[`${item?.ColumnName}`] = item?.valueOftheField === "true" ? true : item?.valueOftheField === 'false' ? false : item?.valueOftheField === true ? true : false
                  } else if (item?.datatype === "int") {
                      data[`${item?.ColumnName}`] = parseInt(item?.valueOftheField)
                  }
                  else if(item?.datatype==='date'){
                    data[`${item?.ColumnName}`] = formatDateForMySQL(item?.valueOftheField)
                }
                  else {
                      data[`${item?.ColumnName}`] = item?.valueOftheField
                  }
              })})
            try {
                const response = await EditDepartments({
                    domain_name: isAuth?.user.domain_name,
                    Deptid: dept_id,
                    data: data
                })
                console.log("Res", response)
                showToast(response.data.status,response.data.message);
                setCustomInput([]);
                setTimeout(()=>{
                    navigate('/ats/dashboard/?type=department')
                },2000)
               
            } catch (err) {
                console.log(err)
            }
        } catch (err) {
            console.log(err)
        }

    }

  return (
    <div
      className="create-job-container"
      style={{ display: "flex", flexDirection: "row" }}
    >
      <div>
        {/* {customInputs.map((item, i) => (
          <div className="dynamic-input-container" key={i}>
            {Object.keys(item).length > 0 && (
              <DynamicTextInput
                remove={Remove}
                onChangeRemove={setRemove}
                index={i}
                {...item}
                value={customInputs}
                customInputs={customInputs}
                onChangeCustomInput={setCustomInput}
                onChangeValue={setCustomInput}
              />
            )}
          </div>
        ))} */}

{customInputs.map((parent_item, parent_index) =>
                        (
                            <div>
                                <p style={{ fontWeight: 'bold', fontSize: '15pt', outline: 'none', border: 'none' }} >{parent_item?.section}</p>
                                <div>
                                    {parent_item?.fields && parent_item?.fields.map((item, i) =>
                                    (

                                        <div
                                            className="bg-white p-4 rounded-lg shadow-md mb-3 flex items-center justify-between">

                                            <DynamicTextInput className='border-b-2 focus:outline-none flex-1 mr-2' key={i} parentInd={parent_index} remove={Remove} onChangeRemove={setRemove} index={i} {...item} value={customInputs} customInputs={customInputs} onChangeCustomInput={setCustomInput} onChangeValue={setCustomInput}
                                            />
                                        </div>
                                    ))}

                                </div>
                            </div>

                        )
                        )}
        <button
          onClick={handleSaveChanges}
         
          
        >
          Update Department
        </button>

        <button
          style={{
            padding: "10px",
            marginLeft: "1%",
            backgroundColor: "#dc3545",
            borderRadius: "7px",
          }}
        >
          <a
            href="/ats/dashboard/?type=department"
            style={{ textDecoration: "none", color: "white" }}
          >
            Cancel
          </a>
        </button>
      </div>
    </div>
  );
}

export default EditDepartments;
