import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useGetDepartmentsQuery } from "../../Redux/API/atsSlice";
import "./Departments.css";
import CommonTable from "../CommonTable/CommonTable";
import { useNavigate } from "react-router-dom";
import { CiSquarePlus } from "react-icons/ci";


const Departments = () => {
  const [deptData, setDeptData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const isAuth = useSelector((state) => state.auth);
  const navigate =useNavigate()


  const { data, isSuccess, refetch } = useGetDepartmentsQuery({
    domain_name: isAuth?.user?.domain_name,
    role_id:isAuth?.user?.role_id,
    user_id:isAuth?.user?.user_id
  });
  useEffect(() => {
    refetch();
  }, [refetch]);
  useEffect(() => {
    if (isSuccess) {
      setDeptData(data?.message || []);
    }
  }, [isSuccess, data]);



  const handleCheckboxChange = (deptId) => {
    setSelectedRows([deptId]);
  };
console.log("department data :",deptData);
const columns = [
  { key: "dept_id", label: "Department Id" },
  { key: "department_name", label: "Department Name" },
  { key: "parent_department", label: "Parent Department" },
  { key: "department_lead", label: "Department Lead" },
  { key: "department_description", label: "Department Description" },
 
 

];
const sidebarLabels = [
  "All Jobs",
  "Active Jobs",
  "Inactive Jobs",
  "Recent Jobs",
  "My Jobs",
];

const [selectedFilter, setSelectedFilter] = useState("All Departments");
  const [sortOrder, setSortOrder] = useState("Newest First");
  const [selectedColumns, setSelectedColumns] = useState([
    "dept_id", "department_name", "parent_department", "department_lead", "department_description"
  ]);

  const getFilteredAndSortedDepartments = () => {
    let filteredDepts = [...deptData];

    if (selectedFilter === "Active Departments") {
      filteredDepts = deptData.filter(dept => dept.status === "Active");
    } else if (selectedFilter === "Inactive Departments") {
      filteredDepts = deptData.filter(dept => dept.status === "Inactive");
    } else if (selectedFilter === "Recent Departments") {
      filteredDepts = deptData.slice().sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    }

    if (sortOrder === "Newest First") {
      filteredDepts.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    } else if (sortOrder === "Oldest First") {
      filteredDepts.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
    }

    return filteredDepts;
  };

const handleRowAction = (job) => (
    
    
  <>
    <button 
      onClick={() => navigate(`?type=job_openings&id=${job.job_id}`)}
      className="commonTable-dropdown-item"
    >
      📄 View Job Detail
    </button>
    <button 
      onClick={()=>navigate(`EditJob/${job.job_id}`)}
      className="commonTable-dropdown-item"
    >
      ✏️ Edit Job
    </button>
    <button 
      onClick={() => console.log("Delete job:", job.job_id)}
      className="commonTable-dropdown-item danger"
    >
      ❌ Delete Job
    </button>
  </>
);

  return (
    // <div className="department-container">
    //   {selectedRows.length > 0 && (
    //     <div className="actions">
    //       {selectedRows.length > 0 && (
    //         <a href={`/ats/dashboard/EditDepartment/${selectedRows[0]}`}>
    //           <button className="edit-button">Edit</button>
    //         </a>
    //       )}
          
          
    //       <button className="delete-button">Delete</button>
    //     </div>
    //   )}
    //   <h2 className="department-title">Departments</h2>
    //   {/**Check the length of the data and map the data accordingly */}
    //   {deptData.length > 0 ? (
    //     <table className="department-table">
    //       <thead>
    //         <tr>
    //           <th className="table-header">Select</th>
    //           <th className="table-header">Department Id</th>
    //           <th className="table-header">Department Name</th>
    //           <th className="table-header">Parent Department</th>
    //           <th className="table-header">Department Lead</th>
    //           <th className="table-header">Description</th>
    //           {/* <th className="table-header">Attachment</th> */}
    //           <th className="table-header">Created At</th>
    //         </tr>
    //       </thead>
    //       <tbody>
    //         {/*here we will get the data in key value pair so based on that we will map the data */ }
    //         {/* {deptData.map((department, deptIndex) => {
    //           let departmentObj = {};
    //           department.forEach((field) => {
    //             const key = Object.keys(field).find(
    //               (k) => k !== "label" && k !== "typeOftheField"
    //             );
    //             if (key) {
    //               departmentObj[key] = field[key];
    //             }
    //           });
    //           return (
    //             <tr key={deptIndex} className="table-row">
    //               <td>
    //                 <input
    //                   type="checkbox"
    //                   onChange={() =>
    //                     handleCheckboxChange(departmentObj.dept_id)
    //                   }
    //                   checked={selectedRows.includes(departmentObj.dept_id)}
    //                 />
    //               </td>
    //               <td className="table-cell">{departmentObj.dept_id}</td>
    //               <td className="table-cell">
    //                 {departmentObj.department_name || "N/A"}
    //               </td>
    //               <td className="table-cell">
    //                 {departmentObj.parent_department || "N/A"}
    //               </td>
    //               <td className="table-cell">
    //                 {departmentObj.department_lead || "N/A"}
    //               </td>
    //               <td className="table-cell">
    //                 {departmentObj.department_description || "N/A"}
    //               </td>
    //               <td className="table-cell">
    //                 {departmentObj.attachment ? "Attached" : "No Attachment"}
    //               </td>
    //               <td className="table-cell">
    //                 {departmentObj.created_at || "N/A"}
    //               </td>
    //             </tr>
    //           );
    //         })} */}
    //         {
    //           deptData.map((dept,i)=>{
    //             return (
    //               <tr key={i} className="table-row">
    //                 <td>
    //                   <input
    //                     type="checkbox"
    //                     onChange={() =>
    //                       handleCheckboxChange(dept.dept_id)
    //                     }
    //                     checked={selectedRows.includes(dept.dept_id)}
    //                   />
    //                 </td>
    //                 <td className="table-cell">{dept.dept_id}</td>
    //                 <td className="table-cell">
    //                   {dept.department_name || "N/A"}
    //                 </td>
    //                 <td className="table-cell">
    //                   {dept.parent_department || "N/A"}
    //                 </td>
    //                 <td className="table-cell">
    //                   {dept.department_lead || "N/A"}
    //                 </td>
    //                 <td className="table-cell">
    //                   {dept.department_description || "N/A"}
    //                 </td>
    //                 {/* <td className="table-cell">
    //                   {departmentObj.attachment ? "Attached" : "No Attachment"}
    //                 </td> */}
    //                 <td className="table-cell">
    //                   {dept.created_at || "N/A"}
    //                 </td>
    //               </tr>
    //             );
    //           })
    //         }
    //       </tbody>
    //     </table>
    //   ) : (
    //     <p className="no-data">No department data available</p>
    //   )}
    // </div>
    
    <CommonTable 
    headerButton="Create Department"
    headerIcon={<CiSquarePlus />}
    headerNavigateTo="/ats/dashboard/?type=createDepartments"
    columns={columns}
    // data={deptData}
    data={getFilteredAndSortedDepartments()}
    onRowAction={handleRowAction}
    title={selectedFilter}
    sidebarLabels={sidebarLabels}
    onFilterChange={setSelectedFilter}
    onSortChange={setSortOrder}
    selectedColumns={selectedColumns} // ✅ Pass selected columns
    onColumnChange={setSelectedColumns}
    />
  );
};

export default Departments;
