import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import "./Modal.css";
import {
  useGetDropDownDataQuery,
  usePostCustomCityMutation,
  usePostCustomCountryMutation,
  usePostDropDowndataMutation,
  usePutUpdateDropDownDataMutation,
} from "../../Redux/API/atsSlice";
import { useSelector } from "react-redux";
import { showToast } from "../ToastContainer/ToastProvider";


const CustomModal = ({
  isOpen,
  close,
  title,
  type,
  selectedNamefromDropDown,
  placeholder,
  parent_ind,
  index,
  customInputs,
  onChangeValue,
  details,
}) => {
  const isAuth = useSelector((state) => state.auth);
  const { data: DropDownDataForUpdation, isSuccess: DropDownDataForSuccess } =
    useGetDropDownDataQuery({
      domain_name: isAuth?.user?.domain_name,
      id: details?.id,
    });

  useEffect(() => {
    if (details?.title === "Update" && DropDownDataForUpdation?.status) {
      setdata(
        [...DropDownDataForUpdation?.message]?.map((item, i) => item.label) || []
      );
    }
  }, [DropDownDataForSuccess]);

  const onRequestClose = (e) => {
    e.preventDefault();
    close(!isOpen);
  };

  const [input, setinput] = useState("");
  const [postDropDownData] = usePostDropDowndataMutation();
  const handleDropDown = async () => {
    const random = Math.random() * 9000;
    const id = Math.ceil(random);
   const needtoUpdate = customInputs.map((item,idx)=>
      parent_ind===idx?{
        ...item,
        fields:item?.fields.map((ele,i)=>
          index===i?{
            ...ele,
            dataForDropdown: id
          }:{...ele}
        )
      }:{...item}
    )
    onChangeValue(needtoUpdate);
    try {
      const response = await postDropDownData({
        domain_name: isAuth?.user?.domain_name,
        data: JSON.stringify(data),
        dropdown_id: id,
      });
      console.log("res", response);
      showToast(response.data.status,response.data.message)
  //    showToast.success("Dropdown saved successfully!");
    } catch (err) {
      console.log(err);
      // showToast(false,"Hello")
     // showToast.error("Failed to save dropdown!");
    }
  };

  const [data, setdata] = useState(["Option1", "Option2"]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        contentLabel="custom-modal"
        className={{
          base: "custom-modal-base",
          afterOpen: "custom-modal-base_after-open",
          beforeClose: "custom-modal-base_before-close",
        }}
        overlayClassName={{
          base: "custom-overlay-base",
          afterOpen: "custom-overlay-base_after-open",
          beforeClose: "custom-overlay-base_before-close",
        }}
        shouldCloseOnOverlayClick={true}
        closeTimeoutMS={2000}
      >
        <div className="custom-modal-header">
          <h5 className="custom-modal-title">{title}</h5>
          <button
            className="custom-modal-close"
            onClick={(e) => onRequestClose(e)}
          >
            Close
          </button>
        </div>
        {type === "dropdownInput" ||
        type === "multiselectInput" ||
        type === "radioInput" ? (
          <div className="custom-modal-content">
            <div style={{ display: "flex" }}>
              <input
                type="text"
                placeholder="Enter your Value"
                value={input}
                onChange={(e) => setinput(e.target.value)}
                className="custom-modal-input"
              />
              <button
                className="custom-modal-add-button"
                onClick={() => {
                  if (input.trim()) {
                    setdata([...data, input]);
                    // showToast(true,"Item added successfully!")
                  ///  showToast.success("Item added successfully!");
                    setinput(""); 
                  } else {
                    // showToast(false,"Input cannot be empty!")
                  //  showToast.error("Input cannot be empty!");
                  }
                }}
              >
                Add
              </button>
            </div>
            <div className="custom-modal-data-list">
              {data &&
                data.map((item, i) => (
                  <div key={i} className="custom-modal-data-item">
                    <p className="custom-modal-data-text">{item}</p>
                    <button
                      className="custom-modal-delete-button"
                      onClick={() => {
                        setdata(data.filter((_, index) => index !== i));
                      //  showToast.success(`Item "${item}" deleted successfully!`);
                      }}
                    >
                      Delete
                    </button>
                  </div>
                ))}
            </div>

            <button
              className="custom-modal-save-button"
              onClick={() => handleDropDown()}
            >
              Save Changes
            </button>
            <button
              className="custom-modal-close"
              onClick={(e) => onRequestClose(e)}
            >
              Cancel
            </button>
          </div>
        ) : (
          <></>
        )}
      </Modal>
    </>
  );
};

export default CustomModal;
