import React, { useEffect, useState } from "react";
import { useGetSettingsQuery, useUpdateSettingsMutation } from "../../../../Redux/API/atsSlice";
import { useSelector } from "react-redux";


const DashBoardSettings = () =>{
    const isAuth = useSelector((state) => state.auth);
    const [setting,setSetting] = useState()

    const {
        data, isSuccess, refetch
    } = useGetSettingsQuery({
        domain_name:isAuth?.user?.domain_name
    })
    console.log("Data",data)
    useEffect(()=>{
        if(data?.status){
            setSetting(data?.message)
        }
    },[isSuccess])
    const [update] = useUpdateSettingsMutation()
    const handleUpdateSettings = async (req,res)=>{
        try{
            const res = await update({
                domain_name:isAuth?.user?.domain_name,
                data:setting
            })
            console.log("res",res)
        }catch(err){

        }
    }

    return(
       <div>
        {
            setting && setting.map((item,i)=>{
                return(
                    <>
                    <p>{item?.label}</p>
                    <input type={item?.type} checked={item?.is_enabled} value={item?.is_enabled} onChange={(e)=>{
                        setSetting((prev) => 
                            prev.map((val, idx) => 
                                idx === i ? { ...val, is_enabled: parseInt(val.is_enabled) === 1 ? 0 : 1 } : val
                            )
                        );
                        
                    }} />
                    </>
                )
            })
        }
        <button onClick={handleUpdateSettings}>Save Changes</button>
       </div>
    )
}


export default DashBoardSettings