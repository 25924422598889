
import React from 'react'
import RouteComponent from './Routes/Route'
import { useTheme } from './Components/Context/ThemeContext'
import './App.css';
import './index.css'
import { useEffect } from 'react';
import { applyTheme } from './Common/theme/theme';

const App = () => {
const {theme} = useTheme();

  useEffect(() => {
    applyTheme(); // Apply theme colors on component mount
  }, []);

  return (
    <div className={`landing-page ${theme === 'dark' ? 'dark' : 'light'}`}>
      <RouteComponent/>
    </div> 
  )

}

export default App
