import React, { useEffect, useState } from "react";
import renderHTML from "react-render-html";
import { useSearchParams, useNavigate } from "react-router-dom";
import "./JobDetail.css";
import editIcon from "../../Assests/editIcon.png";
import accordionIcon from '../../Assests/accordionArrow.png'
import editIconWhite from '../../Assests/editIconWhite.png'
import { MdKeyboardArrowRight } from "react-icons/md";

import createNewIcon from '../../Assests/createNewIcon.svg'

import { useGetCustomerJobsByIdQuery } from "../../Redux/API/atsSlice";
import { useSelector } from "react-redux";
import { Search } from "lucide-react";
import usePrivilege from "../Privileges/Privileges";
import MetaHelmet from "../Helmet/Helmet";
import { Helmet } from "react-helmet";

function JobDetail() {
  const [Job, setJob] = useState([]);
  const [search] = useSearchParams();
  const [id, setId] = useState("");
  const [openAccordion, setOpenAccordion] = useState([]);
  const navigate = useNavigate();
  const isAuth =useSelector((state)=>state.auth);

  const isJobUpdate = usePrivilege('Job Update')

  const{data,refetch,isSuccess}=useGetCustomerJobsByIdQuery({
    domain_name:isAuth?.user?.domain_name,
    Jobid:search.get("id") || "null"
  })


const job_id = search.get("id") || "null"
  

useEffect(()=>{
  if(isSuccess && data?.status){
    setJob(data?.message || [])
          const initialAccordionState = data?.message.map((section) =>
        section.values?.some((item) => Object.values(item)[1] === "editor")
      );
      setOpenAccordion(initialAccordionState);
  }

},[data,isSuccess])



  // useEffect(() => {
  //   if (data) {
  //     setJob(data);
  //     setId(search.get("id"));


  //     const initialAccordionState = data.map((section) =>
  //       section.values?.some((item) => Object.values(item)[1] === "editor")
  //     );
  //     setOpenAccordion(initialAccordionState);
  //   }
  // }, [data]);
  // console.log("dataaa", data)

  // const handleEditClick = () => {
  //   if (jobId) {
  //     console.log("idddd",jobId)
  //     navigate(`/ats/dashboard/EditJob/${jobId}`);
      
  //   }
  // };

  const handleEditClick = () => {
    if (job_id) {
      console.log("idddd",job_id)
      navigate(`/ats/dashboard/EditJob/${job_id}`);
      
    }
  };

  const toggleAccordion = (index) => {
    setOpenAccordion((prev) =>
      prev.map((isOpen, i) => (i === index ? !isOpen : isOpen))
    );
  };

  const handleBackToList = () => {
    navigate(`?type=job_openings`)
  }

  return (
    <>
    <Helmet  title="JobDetailView"/>
    <div className="job-detail-main-container">
      
      <div className="job-detail-container">


        {/* Edit Button */}
        {id && (
          <div className="edit-button-container">
            <h2 className="viewJobDetail-heading">View Job Details</h2>
            {/* <button className="job-detail-editbtn" onClick={handleEditClick}>
            Edit
            <img src={editIcon} alt="edit Icon" className="edit-iconImage" />
          </button> */}
          </div>
        )}

        {Job.map((section, index) => {
          const hasEditorField = section.values?.some(
            (item) => Object.values(item)[1] === "editor"
          );

          return (
            <div key={index} className="job-section">
              {/* Clickable section title for accordion toggle */}
              <h1
                className="job-section-title"
                onClick={() => hasEditorField && toggleAccordion(index)}
              >
                {section.section}
                {hasEditorField && (
                  <span className={`accordion-icon ${openAccordion[index] ? "open" : ""}`}>
                    <img src={accordionIcon}></img>
                  </span>
                )}
              </h1>

              {/* Show content based on accordion state */}
              {(!hasEditorField || openAccordion[index]) && (
                <div className="job-detail-table-wrapper">
                <table className="job-detail-table">
                  <tbody>
                    {section.values?.map((item, i) => {
                      const [firstKey, firstValue] = Object.entries(item)[0];
                      const [secondKey, secondValue] = Object.entries(item)[1];
                      return secondValue === "editor" ? (
                        <React.Fragment key={i}>
                        
                          <tr className="job-detail-table-row">
                            <td className="job-detail-label-foreditor" colSpan="2">
                              {firstKey}
                            </td>
                          </tr>
                          <tr className="job-detail-table-row">
                            <td className="job-detail-value" colSpan="2" style={{ backgroundColor: "white" }}>
                              <div className="job-detail-html-content" style={{ backgroundColor: "white", width: "100%" }}>
                                {renderHTML(firstValue)}
                              </div>
                            </td>
                          </tr>
                        </React.Fragment>
                      ) : (
                        <tr key={i} className="job-detail-table-row">
                          <td className="job-detail-label">{firstKey}</td>
                          <td className="job-detail-value">
                            <p>{firstValue}</p>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                </div>
              )}
            </div>
          );
        })}
        <div className="job-detail-bottom-container" >
          <button className="job-detail-editbtn job-detail-backtolist" onClick={handleBackToList}>Back to List</button>
          <button className="job-detail-editbtn" disabled={!isJobUpdate.includes(isAuth?.user?.user_id)} onClick={handleEditClick}>
            Edit
            <img src={editIconWhite} alt="edit Icon" className="edit-iconImage" />
          </button>

        </div>
      </div>
      </div>

    </>
  );
}

export default JobDetail;
