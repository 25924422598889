import { configureStore, combineReducers } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage'; 
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import resumeReducer from "../ResumeParser/parser-redux/resumeSlice.ts";
import settingsReducer from "../ResumeParser/parser-redux/settingsSlice.ts";
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist';

import { homeSlice } from './API/homeSlice';
import apiSlice from './API/apiSlice';
import authReducer from './API/authSlice'

const reducers = combineReducers({
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: authReducer,
    resume: resumeReducer,
    settings: settingsReducer,  
});
const appReducer = (state, action) => {
    if (action.type === "auth/logout") {
      storage.removeItem("persist:root"); 
      return reducers(undefined, action); 
    }
    return reducers(state, action);
  };

const persistConfig = {
    key: 'root',
    version: 1,
    storage,
    stateReconciler: autoMergeLevel2,
    whitelist:['auth'] 
};

const persistedReducer = persistReducer(persistConfig, appReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoreActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }).concat(apiSlice.middleware),
});

export let persistor = persistStore(store);
export type RootState = ReturnType<typeof reducers> & { _persist: any };
export type AppDispatch = typeof store.dispatch;

