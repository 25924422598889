import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import "./ContactUs.css";
import { useGetAtsHomePageQuery, usePostContactInformationMutation } from "../../Redux/API/atsSlice";
import { BASE_URL } from "../../Redux/API/apiSlice";
import { showToast } from "../ToastContainer/ToastProvider";

const ContactUs = () => {
  const [mainHeading, setMainHeading] = useState('');
  const [subHeading, setSubHeading] = useState('');
  const [contact, setContact] = useState([]);

  const { data, isSuccess } = useGetAtsHomePageQuery();
  const [ContactInformation] = usePostContactInformationMutation();

  useEffect(() => {
    if (isSuccess && data?.message) {
      const contactSection = data.message.find(section => section.section === "contactus");
      if (contactSection) {
        setMainHeading(contactSection?.values.find(val => val.type === 'h5')?.content || "");
        setSubHeading(contactSection?.values.find(val => val.type === 'h3')?.content || "");

        const connections = contactSection?.values.find(val => val.type === "array")?.content;
        if (connections) {
          try {
            setContact(JSON.parse(connections));
          } catch (err) {
            console.error("Error parsing contact info", err);
          }
        }
      }
    }
  }, [data, isSuccess]);

  //  Yup Validation Schema
  const validationSchema = Yup.object({
    fullName: Yup.string()
      .min(3, "Full Name must be at least 3 characters")
      .required("Full Name is required"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    mobileNumber: Yup.string()
      .matches(/^\d{10}$/, "Mobile Number must be exactly 10 digits"),
    message: Yup.string()
      .min(10, "Message must be at least 10 characters")
      .required("Message is required"),
  });

  //  Formik Hook
  const formik = useFormik({
    initialValues: {
      fullName: "",
      email: "",
      mobileNumber: "",
      message: ""
    },
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      console.log("Submitting Values: ", values); 
    
      const formattedData = {
        full_name: values.fullName,
        email: values.email,
        mobile_number: values.mobileNumber,
        message: values.message,
      };
      console.log(formattedData)
      try {
        const response = await ContactInformation({data:formattedData});
        console.log("API Response: ", response); 
        showToast(response?.message)
        resetForm();
      } catch (err) {
        console.error("Error submitting form", err);
       
      }
    }
    
    
  });



useEffect(() => {
    if (isSuccess && data?.message) {
     const contactSection = data.message.find(section => section.section === "contactus");
     if (contactSection) {
        setMainHeading(contactSection?.values.find(val => val.type === 'h5')?.content || "");
        setSubHeading(contactSection?.values.find(val => val.type === 'h3')?.content || "");

        const connections = contactSection?.values.find(val => val.type === "array")?.content;
        if (connections) {
         try {
            setContact(JSON.parse(connections));
         } catch (err) {
            console.error("Error parsing contact info", err);
         }
        }
     }
    }
}, [data, isSuccess]);



return (
    <div className="ats-contactUs-container">
      {/* Left Section */}
      <div className="ats-contactUs-left">
        <h1 className="ats-contactUs-heading">
          <span className="ats-contactUs-highlight">{mainHeading.split(' ')[0]}</span> {mainHeading.split(' ').slice(1).join(" ")}
        </h1>
        <p className="ats-contactUs-text">{subHeading}</p>

        <div className="ats-contactUs-info">
          {contact.slice(0, 2).map((item, index) => (
            <p key={index} className="ats-contactUs-email">
              <img src={`${BASE_URL}${item?.icon}`} alt="icon" className="contactus-icon-image" />
              <strong>{item?.label}:</strong> {item?.value}
            </p>
          ))}
        </div>

        {/* Social Media Icons */}
        <div className="ats-contactUs-social">
          <p className="ats-contactUs-social-text">
            <img src={`${BASE_URL}${contact[2]?.icon}`} alt="icon" className="contactus-icon-image" />
            <strong>{contact[2]?.label}</strong>
          </p>
          <div className="ats-contactUs-icons">
            {contact[2]?.values?.map((social, index) => (
              <a key={index} href={social.route || "#"} target="_blank" rel="noopener noreferrer">
                <img src={`${BASE_URL}${social.icon}`} alt={`Social ${index}`} className="ats-contactUs-icon" />
              </a>
            ))}
          </div>
        </div>
     </div>

      {/* Right Section (Formik Form) */}
      <div className="ats-contactUs-form">
        <form onSubmit={formik.handleSubmit}>
          <label className="ats-contactUs-label">Full Name (Required)</label>
          <input
            type="text"
            name="fullName"
            placeholder="Enter your name"
            className="ats-contactUs-input"
            value={formik.values.fullName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.fullName && formik.errors.fullName && (
            <p className="error-text">{formik.errors.fullName}</p>
          )}

          <label className="ats-contactUs-label">Email Address (Required)</label>
          <input
            type="email"
            name="email"
            placeholder="Enter your email"
            className="ats-contactUs-input"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.email && formik.errors.email && (
            <p className="error-text">{formik.errors.email}</p>
          )}

          <label className="ats-contactUs-label">Mobile Number</label>
          <input
            type="tel"
            name="mobileNumber"
            placeholder="Enter your Mobile Number"
            className="ats-contactUs-input"
            value={formik.values.mobileNumber}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.mobileNumber && formik.errors.mobileNumber && (
            <p className="error-text">{formik.errors.mobileNumber}</p>
          )}

          <label className="ats-contactUs-label">Message (Required)</label>
          <textarea
            name="message"
            placeholder="Write your inquiry or feedback"
            className="ats-contactUs-textarea"
            value={formik.values.message}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          ></textarea>
          {formik.touched.message && formik.errors.message && (
            <p className="error-text">{formik.errors.message}</p>
          )}

          <button type="submit" className="ats-contactUs-button" 
          >
            {formik.isSubmitting ? "Sending..." : "Send Message"}
          </button>
        </form>
      </div>
    </div>
);
};

export default ContactUs;