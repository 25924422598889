
import React, { useEffect, useState } from "react";
import "./AtsDashBoardSubHeader.css";
import { CiSearch } from "react-icons/ci";
import { FcDepartment } from "react-icons/fc";
import {
  MdOutlineAddBox,
  MdOutlineWidgets,
  MdOutlinePersonAddAlt,
} from "react-icons/md";
import { LuSquarePen } from "react-icons/lu";
import { useGetCustomerMenuItemQuery, useGetSettingsQuery } from "../../Redux/API/atsSlice";
import { useSelector } from "react-redux";
import { BASE_URL } from "../../Redux/API/apiSlice";
import usePrivilege from "../Privileges/Privileges";
import CandidateDetail from "../Candidates/CandidateDetail";

const footerWidgets = ["Today's Interview", "Quick Actions"];
const widgets = [
  "Job Posting",
  "Candidates",
  "Interviews",
  "Job Listing",
  "Recent Activities",
];

const AtsDashBoardSubHeader = () => {
  const [addNew, setAddNew] = useState(false);
  const [buttonName, setButtonName] = useState([]);
  const [editWidget, setEditWidget] = useState(false);
  const [menuEnable,setMenuEnable] =useState([])

  const isAuth = useSelector((state) => state.auth);
  const { data, isSuccess } = useGetCustomerMenuItemQuery({
    domain_name: isAuth?.user.domain_name,
  });

  useEffect(() => {
    if (isSuccess && data?.message) {
      const dashboardSec = data.message.find(
        (sec) => sec.header_name === "dashboard_header"
      );
      if (dashboardSec?.items?.length) {
        const menuItems = dashboardSec.items.map((item) => ({
          name: item.name_of_the_menu || "Unknown",
          icon: item.icon || "default-icon.svg",
          children: item.child || [],
        }));
        setButtonName(menuItems);
      }
    }
  }, [isSuccess, data]);


  const {data:settingsData,isSuccess:settingsIsSuccess,refetch:settingsRefetch}=useGetSettingsQuery({
    domain_name:isAuth?.user?.domain_name
  })

  useEffect(() => {
    if (settingsIsSuccess && settingsData?.message) {
      const searchSetting = settingsData.message.find((item) => item.name_of_the_menu === "is_search");
      const widgetSetting = settingsData.message.find((item) => item.name_of_the_menu === "is_edit_widget");

      setMenuEnable({
        is_search: searchSetting?.is_enabled || 0,
        is_edit_widget: widgetSetting?.is_enabled || 0,
      });
    }
  }, [settingsIsSuccess, settingsData]);

  const jobCreate = usePrivilege("Job Create");

  const deptCreate = usePrivilege("Department Create");
  const candidatecreate = usePrivilege("Candidate Create");
  const interviewcreate = usePrivilege("Interview Create");

  return (
    <>
      <div className="ats-subheader-container">
        {/* Left - Title & Search */}
        <div className="ats-subheader-heading-search-con">
          <h1 className="ats-subheader-title">Dashboard</h1>
          {/* <div className="ats-subheader-search-wrapper">
            <CiSearch className="ats-subheader-search-icon" />
            <input
              type="text"
              className="ats-subheader-search"
              placeholder="What are you looking for?"
            />
          </div> */}
           {menuEnable.is_search === 1 && (
            <div className="ats-subheader-search-wrapper">
              <CiSearch className="ats-subheader-search-icon" />
              <input type="text" className="ats-subheader-search" placeholder="What are you looking for?" />
            </div>
          )}
        </div>

        {/* Right - Buttons */}
        <div className="ats-subheader-buttons">
          {/* Add New Dropdown */}
          {buttonName[0] && (
            <div className="ats-subheader-dropdown">
              <button
                className="ats-subheader-btn"
                onClick={() => setAddNew(!addNew)}
              >
                {buttonName[0]?.name + " "}
                <span style={{ paddingLeft: "0.5rem" }}>
                  <img
                    src={`${BASE_URL}${buttonName[0]?.icon}`}
                    alt="icon"
                    className="menu-icon"
                  />
                </span>
              </button>
              {addNew && (
                <ul className="ats-subheader-dropdown-menu">
                  {buttonName[0]?.children.length > 0 ? (
                    buttonName[0].children
                      .filter((child) => {
                        const permissions = {
                          Job: jobCreate,
                          Departments: deptCreate,
                          Candidate: candidatecreate,
                          Interview: interviewcreate,
                        };
                        return permissions[child.name_of_the_menu]?.includes(
                          isAuth.user?.user_id
                        );
                      })
                      .map((child) => (
                        <li key={child.id}>
                          <a href={child.route_name}>
                            {child.name_of_the_menu}
                          </a>
                        </li>
                      )
                    )
                    // buttonName[0].children
                    // ?.map((child) => (
                    //   <li key={child.id}>
                    //     <a href={child.route_name}>
                    //       {child.name_of_the_menu}
                    //     </a>
                    //   </li>
                    // )
                 // )
                  ) : (
                    <li>No Access</li> 
                  )}
                </ul>
              )}
            </div>
          )}

          {/* Edit Widget Button */}
          {/* {buttonName[1] && (
            <button
              className="ats-subheader-btn"
              onClick={() => setEditWidget(true)}
            >
              {buttonName[1]?.name + " "}
              <span style={{ paddingLeft: "0.5rem" }}>
                <img
                  src={`${BASE_URL}${buttonName[1]?.icon}`}
                  alt="icon"
                  className="menu-icon"
                />
              </span>
            </button>
          )} */}

{menuEnable.is_edit_widget === 1 && buttonName[1] && (
            <button className="ats-subheader-btn" onClick={() => setEditWidget(true)}>
              {buttonName[1]?.name + " "}
              <span style={{ paddingLeft: "0.5rem" }}>
                <img src={`${BASE_URL}${buttonName[1]?.icon}`} alt="icon" className="menu-icon" />
              </span>
            </button>
          )}
        </div>
      </div>

      {/* Edit Widget Modal */}
      <div
        className={`ats-subheader-modal-overlay ${editWidget ? "active" : ""}`}
        onClick={() => setEditWidget(false)}
      >
        <div
          className="ats-subheader-modal"
          onClick={(e) => e.stopPropagation()}
        >
          {/* Modal Header */}
          <div className="ats-subheader-modal-header">
            <h4>Edit Widget</h4>
            <p
              className="ats-subheader-modal-close"
              onClick={() => setEditWidget(false)}
            >
              ✖
            </p>
          </div>

          {/* Modal Content */}
          <div className="ats-subheader-modal-content">
            {/* Widgets */}
            <div className="ats-subheader-widgets">
              {widgets.map((widget, index) => (
                <div key={index} className="ats-subheader-widget">
                  <span className="ats-subheader-widget-close">✖</span>
                  <FcDepartment className="ats-subheader-widget-icon" />
                  <p className="ats-subheader-widget-icon-name">{widget}</p>
                </div>
              ))}
            </div>

            <hr style={{ borderTop: "1px solid #007bff", marginTop: "1rem" }} />

            {/* Footer Widgets */}
            <div className="ats-subheader-modal-footer">
              {footerWidgets.map((widget, index) => (
                <div key={index} className="ats-subheader-footer-widget">
                  <FcDepartment className="ats-subheader-widget-icon" />
                  <p className="ats-subheader-widget-icon-name">{widget}</p>
                </div>
              ))}
            </div>

            <hr
              style={{
                borderTop: "1px solid #007bff",
                width: "100%",
                margin: "1rem 0",
              }}
            />

            {/* Action Buttons */}
            <div className="ats-subheader-modal-actions">
              <button
                className="ats-subheader-footer-cancelbtn"
                onClick={() => setEditWidget(false)}
              >
                Cancel
              </button>
              <button className="ats-subheader-footer-savebtn">
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AtsDashBoardSubHeader;
