import React from 'react';
import './JDHeader.css';
import { MdKeyboardArrowRight } from "react-icons/md";

const JDHeader = () => {
  return (
    <div className="job-detail-header-container-oop">
            {/* Breadcrumb Navigation */}
            <div className="breadcrumb">
              <a href={`?type=job_openings`} className="breadcrumb-job-opening">Job opening</a>
              <span className="chevron"> <MdKeyboardArrowRight size={23} /> </span>
              <a className="breadcrumb-link">View Job Details</a>
            </div>
          </div>
  )
}

export default JDHeader
