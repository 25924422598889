import React, { useState } from "react";
import "./Accordion.css"; 
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

const Accordion = ({ title, children, isOpen, onClick, }) => {


  return (
    <div className={`accordion ${isOpen ? "open" : ""}`}>
      <div className="accordion-header" onClick={onClick}>
        <p>{title}</p>
        {isOpen ? <FaChevronUp /> : <FaChevronDown />}
      </div>
      <div className="accordion-content">{children}</div>
    </div>
  );
};

export default Accordion;
