
import React, { useEffect, useMemo, useState } from "react";
import { FaRegClock } from "react-icons/fa";
import "./CandidateOverview.css";
import onGoingInterview from "../../../../Assests/CandidateDetailImages/OnGoingInterview.svg";
import selected from "../../../../Assests/CandidateDetailImages/Selected.svg";
import rejected from "../../../../Assests/CandidateDetailImages/Rejected.svg";
import similarProfile from "../../../../Assests/CandidateDetailImages/SimiliarProfiles.svg";
import submitted from "../../../../Assests/CandidateDetailImages/Submited.svg";
import accepted from "../../../../Assests/CandidateDetailImages/Accepted.svg";
import interviewed from "../../../../Assests/CandidateDetailImages/Interviewed.svg";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useGetActivityQuery, useGetCandidateInterviewsQuery, useGetCandidateJobsQuery } from "../../../../Redux/API/atsSlice";
import { FaPlusCircle, FaEdit, FaTrash } from "react-icons/fa";
import attachActivity from "../../../../Assests/CandidateDetailImages/AttachActivity.svg"
import deleteActivity from "../../../../Assests/CandidateDetailImages/DeleteActivity.svg";
import createActivity from "../../../../Assests/CandidateDetailImages/CreatedcandActivity.svg";
import editACtivity from "../../../../Assests/CandidateDetailImages/EditedActivity.svg";
import Dot from "../../../../Assests/CandidateDetailImages/DotActivity.svg";


const CandidateOverview = () => {
  const [data, setData] = useState(null);
  const [activityData, setActivityData] = useState()
  const [params] = useSearchParams();
  const isAuth = useSelector((state) => state.auth)

  const { data: activitydata, isSuccess, refetch } = useGetActivityQuery({
    domain_name: isAuth?.user?.domain_name,
    id: params.get("id") || "null",
  })
  const navigate = useNavigate()
  const location = useLocation()
  useEffect(() => {
    refetch()
  }, [])

  useEffect(() => {
    setActivityData(activitydata)
  }, [isSuccess]);

  const activityIcons = {
    create: createActivity,
    update: editACtivity,
    delete: deleteActivity,
    attach:attachActivity,
    };
    
    const {
      data:CandidateInterview, refetch:CandidateInterviewRefetch, isSuccess:CandidateInterViewSuccess
    } = useGetCandidateInterviewsQuery({
      domain_name:isAuth?.user?.domain_name,
      id:params.get("id") || 'null'
    })
   
  //   const upComingInterviews = useMemo(() => {
  //     return CandidateInterview?.message?.interviews?.filter(
  //         (item) => new Date(item?.interview_date).getTime() > new Date().getTime()
  //     ) || [];
  // }, [CandidateInterview,CandidateInterViewSuccess]);

  const upComingInterviews = useMemo(() => {
    console.log("Raw interview dates:", CandidateInterview?.message?.interviews);

    return CandidateInterview?.message?.interviews
        ?.filter((item) => {
            const interviewDate = new Date(item?.interview_date);
            console.log("Parsed Date:", interviewDate, "Timestamp:", interviewDate.getTime());
            return interviewDate.getTime() > new Date().getTime();
        }) || [];
}, [CandidateInterview]);


    const {
      data:CandidateJobs
    } = useGetCandidateJobsQuery({
       domain_name:isAuth?.user?.domain_name,
      id:params.get("id") || 'null'
    })
  const formatDate = (dateString) => {
    if (!dateString || dateString === "N/A") return "N/A";

    const date = new Date(dateString);
    if (isNaN(date)) return "N/A";
    return new Intl.DateTimeFormat("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    }).format(date);  
  };


 //console.log("iterer",CandidateInterview?.pastInterviewsCount)
  useEffect(() => {
    const dummyData = {
      todo: {
        tasks: 2,
        submissions: 3,
        interviews: 1,
        feedback: 0,
      },
      status: [
        { label: "Submitted", count: CandidateJobs?.message?.jobs?.length || 0, change:CandidateJobs?.message?.pastJobs || 0 , icon: submitted },
        { label: "Interviewed", count: CandidateInterview?.message?.interviews?.length || 0, change: CandidateInterview?.message?.pastInterviewsCount || 0, icon: interviewed },
        { label: "Selected", count: 10, change: 0, icon: selected },
        { label: "Accepted", count: 10, change: 0, icon: accepted },
        { label: "Rejected", count: 0, change: 0, icon: rejected },
        { label: "Similar Profiles", count: 0, change: 0, icon: similarProfile },
      
      ],
      activity: [
        {
          date: "19 Mar 2025, 11:30 AM",
          description: 'Status changed from "N/A" to "Interested"',
          user: "keerthana",
        },
        {
          date: "12 Feb 2025, 10:30 AM",
          description: 'Candidate "CID - 3076 - keerthana" created',
          user: "keerthana",
        },
      ],
    };
    
    setTimeout(() => {
      setData(dummyData);
    }, 1000);
  }, [CandidateJobs,CandidateInterview]);

  if (!data) return <p>Loading...</p>;

  return (
    <div className="candidate-overview-container">
      {/* To-Do Section */}
      <div className="candidate-overview-to-do-section">
        <h3>To-Do</h3>
        <ul>
          <li>{data.todo.tasks} Tasks pending</li>
          <li>{data.todo.submissions} Submissions pending</li>
          <li style={{textDecoration:'underline',cursor:'pointer'}} onClick={()=>{
              navigate("?type=interviews",{
                state:{key:params.get("id"),value:upComingInterviews}
              })
              
          }}>{upComingInterviews?.length || 0} Interviews upcoming</li>
          <li>{data.todo.feedback} Interviews awaiting feedback</li>
        </ul>
      </div>

      {/* Status Cards */}
      <div className="candidate-overview-status-cards">
        {data.status.map((item, index) => (
          <div key={index} className="candidate-overview-status-card">
            <div className="candidate-overview-status-header">
              <span>{item.label}</span>
              <img src={item.icon} alt={item.label} className="candidate-overview-status-card-icons" />
            </div>
            <h2>{item.count}</h2>
            <p className={item?.change>0 ? "candidate-overview-positive" : "candidate-overview-negative"}>
            <div className={item?.change>0?"candidate-overview-up-arrow":"candidate-overview-down-arrow"}></div>{item?.change>0 && <span>+</span>}{item?.change}<span style={{ color: 'black', fontWeight: 'normal' }}>Last 30 days</span> 
            </p>
           
          </div>
        ))}
      </div>

      {/* Activity Log */}
      {/* <div className="candidate-overview-activity-log">
        <h4>Activity</h4>
        <ul>
          {data.activity.map((log, index) => (
            <li key={index}>
              <FaRegClock /> {log.date} {log.description}{" "}
              <span className="candidate-overview-activity-user">by {log.user}</span>
            </li>
          ))}
        </ul>
      </div> */}
      <div className="candidate-overview-activity-log">
        <h4>Activity</h4>
        <ul>
          {isSuccess && activityData?.message?.length > 0 ? (
            activityData.message.map((log, i) => (
              <li key={log.id} className="activity-item">
                <span className={i !== activityData?.message?.length - 1 ? "activity-icon-container " : ""}>
                  {/* {activityIcons[log.type] || <FaRegClock className="activity-icon default" />} */}
                  <img
                    src={activityIcons[log.type] || Dot}
                    alt={log.type}
              
                  />
                </span>
                <span className="activity-text">
                  <strong>{log.title}</strong> by {log.done_by}, {formatDate(log.done_at)}
                </span>
              </li>
            ))
          ) : (
            <p>No activity found.</p>
          )}
        </ul>
      </div>
    </div>
  );
};

export default CandidateOverview;
