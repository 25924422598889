import { useState, useEffect } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import "./Carousel.css";
import img1 from "../../Assests/Candidates.png";
import img2 from "../../Assests/Hiring-manager.png";
import img3 from "../../Assests/Recruiters.png";
import img4 from "../../Assests/admin.png";
import icon1 from "../../Assests/candidate-frame.png";
import icon2 from "../../Assests/HRmanager-frame.png";
import icon3 from "../../Assests/Admin-frame.png";
import { useGetAtsHomePageQuery } from "../../Redux/API/atsSlice";
import { BASE_URL } from "../../Redux/API/apiSlice";
import usecaseicon from "../../Assests/usecaseicon.png"



const CarouselSection = () => {
  const [activeTab, setActiveTab] = useState("Candidates");
  const [carouselData, setCarouselData] = useState([]);
  const [carouselHeading, setCarouselHeading] = useState('')

  const { data: carousel, isSuccess } = useGetAtsHomePageQuery({});

  useEffect(() => {
    if (isSuccess && carousel?.message) {
      const carouselSection = carousel.message.find(section => section.section === "use_case");

      if (carouselSection) {
        const heading = carouselSection.values.find((val) => val.type === "h5")?.content || "";
        setCarouselHeading(heading);
        const rawContent = carouselSection.values.find(val => val.type === "array")?.content;


        try {
          if (typeof rawContent === "string") {
            const cleanedContent = rawContent.replace(/\\n/g, "").replace(/\\/g, "");

            const parsedContent = JSON.parse(cleanedContent);
            setCarouselData(parsedContent);
          } else {
            setCarouselData([]);
          }
        } catch (error) {
          console.error("Error parsing JSON:", error, rawContent);
          setCarouselData([]);
        }
      }
    }
  }, [isSuccess, carousel]);



  const handleNext = () => {
    const currentIndex = carouselData.findIndex(tab => tab.title === activeTab);
    const nextIndex = (currentIndex + 1) % carouselData.length;
    setActiveTab(carouselData[nextIndex].title);
  };

  const handlePrev = () => {
    const currentIndex = carouselData.findIndex(tab => tab.title === activeTab);
    const prevIndex = (currentIndex - 1 + carouselData.length) % carouselData.length;
    setActiveTab(carouselData[prevIndex].title);
  };

  return (
    <div className="carousel-container">
      <h1>
        <span className="highlight">{carouselHeading.split(" ")[0]}</span> {carouselHeading.split(" ").slice(1).join(" ")}
      </h1>
      <button className="useCase-btn">
        <img src={usecaseicon} className="usecase-image"></img>
        Use Cases</button>

      {/* Tabs */}
      <div className="tabs-container">
        <div className="tabs">
          {carouselData.map((tab, index) => (
            <button
              key={tab.title || index}
              className={`tab-button ${activeTab === tab.title ? "active" : ""}`}
              onClick={() => setActiveTab(tab.title)}
            >
              {tab.title}
            </button>
          ))}
        </div>
      </div>

      {/* Carousel Content */}
      <div className="carousel-content">
        <button className="nav-button left" onClick={handlePrev}>
          <FaChevronLeft/>
        </button>

        {carouselData.map((item) =>
          activeTab === item.title && (
            <div className="carousel-slider" key={item.title}>
              <div className="carousel-card">
                <div className="card-content">
                  <h1>
                    <img className="heading-icon" src={`${BASE_URL}${item.icon}`} alt="icon" />
                    {item.title}
                  </h1>
                  <p>{item.description}</p>
                </div>
                <div className="card-image">
                  <img src={`${BASE_URL}${item.image}`} alt={item.title} />
                </div>
              </div>
            </div>
          )
        )}

        <button className="nav-button right" onClick={handleNext}>
          <FaChevronRight />
        </button>
      </div>
    </div>
  );
};

export default CarouselSection;
