
import React, { useState, useEffect } from "react";
import { useGetCandidateSettingsQuery, useUpdateCandidateSettingsMutation } from "../../../../Redux/API/atsSlice";
import { useSelector } from "react-redux";

const CandidateSettings = () => {
    const isAuth = useSelector((state) => state.auth);
    
    const { data } = useGetCandidateSettingsQuery({
        domain_name: isAuth?.user?.domain_name,
    });
    
    const [setupData, setSetUpData] = useState([]);
   // console.log(setupData)
    // Update state when API data arrives
    useEffect(() => {
        if (data) {
            setSetUpData([
                { type: "checkbox", metadata: data?.message[0]?.mail_enabled, meta_title: "mail_enabled", title: "Enable Mail" },
                { type: "checkbox", metadata: data?.message[0]?.job_Assign, meta_title: "job_Assign", title: "Enable Assign Job" },
                { type: "checkbox", metadata: data?.message[0]?.submit_hiring_manager, meta_title: "submit_hiring_manager", title: "Enable Submit Hiring Manager" },
                { type: "checkbox", metadata: data?.message[0]?.schedule_interview, meta_title: "schedule_interview", title: "Enable Schedule Interview" }
            ]);
        }
    }, [data]);

    // Handle checkbox toggle
    const handleCheckboxChange = (index) => {
        setSetUpData((prev) => 
            prev.map((item, i) => 
                i === index ? { ...item, metadata: !item.metadata } : item
            )
        );
    };
    const [update] = useUpdateCandidateSettingsMutation() 
    const handleUpdate = async () =>{
        const  payload =  {
            role_id:data?.message[0]?.role_id
        }
        for(let i of setupData){
            payload[i.meta_title] = i?.metadata
        }
        // const data = setupData.map((item,i)=>)
        try{
            const res = await update({
                domain_name:isAuth?.user?.domain_name,
                data:payload
            })
          //  console.log("res",res)
        }catch(err){

        }
    }
    return (
        <div>
            {setupData.map((item, i) => (
                <div key={item.meta_title}>
                    <p>{item.title}</p>
                    <input 
                        type="checkbox" 
                        checked={item.metadata || false} 
                        onChange={() => handleCheckboxChange(i)} 
                    />
                </div>
            ))}
            <button onClick={handleUpdate}>Save Changes</button>
        </div>
    );
};

export default CandidateSettings;