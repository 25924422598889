import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  useGetRoleByIdQuery,
  useLazyGetUserHavePrivilegeQuery,
} from "../../Redux/API/atsSlice";

// Convert to a React Hook
const usePrivilege = (moduleName, reload = false) => {
  const [privilege, setPrivilege] = useState([]);
  const [shouldReload, setShouldReload] = useState(reload); // Rename reload state
  const isAuth = useSelector((state) => state.auth);

  const getTypeFromModule = (moduleName) => {
    switch (moduleName) {
      case "Job": return "is_job";
      case "Job Create": return "job_create";
      case "Job Update": return "job_update";
      case "Job Delete": return "job_delete";
  
      case "Department": return "is_dept";
      case "Department Create": return "dept_create";
      case "Department Update": return "dept_update";
      case "Department Delete": return "dept_delete";
  
      case "Candidate": return "is_candidate";
      case "Candidate Update": return "candidate_update";
      case "Candidate Delete": return "candidate_delete";
      case "Candidate Create": return "candidate_create";
  
      case "Interview": return "is_interview";
      case "Interview Create": return "interview_create";
      case "Interview Update": return "interview_update";
      case "Interview Delete": return "interview_delete";
  
      case "Settings": return "is_settings";
  
      default: return "";
    }
  };

  const type = getTypeFromModule(moduleName); // No need to useState

  const { data: Modules, isSuccess: ModuleSuccess, refetch } = useGetRoleByIdQuery({
    domain_name: isAuth?.user?.domain_name,
    roleid: isAuth?.user?.role_id,
  });
 // console.log("Modules",Modules)
  const [fetchPrivilege] = useLazyGetUserHavePrivilegeQuery();

  const getData = async (type) => {
    try {
      const response = await fetchPrivilege({
        domain_name: isAuth?.user?.domain_name,
        roleid: isAuth?.user?.role_id,
        userid: isAuth?.user?.user_id,
        module: getTypeFromModule(moduleName),
      });
      console.log( isAuth?.user?.role_id," ",isAuth?.user?.user_id," ",type)
      //console.log("type",response)
      if (response.data?.status) {
        setPrivilege(response.data.message);
      } else {
        setPrivilege([]);
      }
    } catch (err) {
      setPrivilege([]);
    }
  };

  useEffect(() => {
    if (shouldReload) {
      refetch();
    }
  }, [shouldReload, refetch]);

  useEffect(() => {
    if (ModuleSuccess && Modules?.status) {
      getData(Modules?.message[0][type] || "");
    }
  }, [ModuleSuccess, Modules, type]);

  return privilege;
};

export default usePrivilege;
