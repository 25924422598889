
import React, { useEffect, useState } from "react";
import { FaSearch, FaFilter, FaSort, FaBars, FaTimes } from "react-icons/fa";
import { BsThreeDots } from "react-icons/bs";
import "./CommonTable.css";
import { useNavigate } from "react-router-dom";
import { GrFormPrevious } from "react-icons/gr";
import { MdNavigateNext } from "react-icons/md";
import { GrFormNext } from "react-icons/gr";
import usePrivilege from "../Privileges/Privileges";
import { useSelector } from "react-redux";
import MetaHelmet from "../Helmet/Helmet";
import { useGetFilterColumnsQuery, useUpdateFilterColumnsMutation } from "../../Redux/API/atsSlice";

const CommonTable = ({
  module_name,
  meta_title,
detailNavigate,
  columns,
  data,
  onRowAction,
  title,
  headerButton,
  headerIcon,
  headerNavigateTo,
  sidebarLabels,
  rowIdentifier = "id",
  privilege,
  onFilterChange,
  onSortChange,
  selectedColumns,
  onColumnChange 

}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [openDropdown, setOpenDropdown] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isSortMenuOpen, setIsSortMenuOpen] = useState(false);
  const [jobsPerPage, setJobsPerPage] = useState(10);
  const navigate = useNavigate()

  const isAccessible = usePrivilege(privilege)

  const indexOfLastJob = currentPage * jobsPerPage;
  const indexOfFirstJob = indexOfLastJob - jobsPerPage;

  const filteredData = data.filter((row) =>
    columns.some((col) =>
      String(row[col.key])?.toLowerCase().includes(searchQuery.toLowerCase())
    )
  );
  
  const currentData = filteredData.slice(indexOfFirstJob, indexOfLastJob);
  const totalPages = Math.ceil(filteredData.length / jobsPerPage);

  const handleDropdownToggle = (rowId) => {
    setOpenDropdown(openDropdown === rowId ? null : rowId);
  };

    const isAuth = useSelector((state) => state.auth);
  const goToNextPage = () =>
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));

  const goToPrevPage = () =>
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  const handleButtonClick = () => {
    if (headerNavigateTo) {
      navigate(headerNavigateTo);
    }

  };
  const goToPage = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const [selectedFilter, setSelectedFilter] = useState("All Jobs");
  const [sortOrder, setSortOrder] = useState("Newest First");


  const handleFilterChange = (filter) => {
    setSelectedFilter(filter);
    onFilterChange(filter); // Notify parent component
  };
   
  const handleSortChange = (order) => {
    
    setSortOrder(order);
    onSortChange(order); // ✅ Notify JobOpening.js
  };

  // const handleColumnSelection = (columnKey) => {
  //   const newColumns = selectedColumns.includes(columnKey)
  //     ? selectedColumns.filter((col) => col !== columnKey)
  //     : [...selectedColumns, columnKey];

  //   onColumnChange(newColumns);
  // };

  const {
    data:getFilterColumn, refetch, isSuccess
} = useGetFilterColumnsQuery({
    domain_name:isAuth?.user?.domain_name,
    module_name:module_name || ""
})
const [filterColumn,setFilterColums] = useState([])
useEffect(()=>{
    if(getFilterColumn?.status){
     setFilterColums(getFilterColumn?.message)
     onColumnChange(getFilterColumn?.message?.filter((item,i)=>parseInt(item?.is_enabled)===1)?.map((item,i)=>item?.column_name))
    }
},[module_name, isSuccess])

const handleColumnSelection = (columnKey) => {
  const newColumns = selectedColumns.includes(columnKey)
   ? selectedColumns.filter((col) => col !== columnKey)
   : [...selectedColumns, columnKey];
   setFilterColums((prev) => {
      return prev.map((item) => {
       if (item?.column_name === columnKey) {
          return { ...item, is_enabled: parseInt(item?.is_enabled) === 1 ? 0 : 1 };
       }
       return { ...item };
      });
   });
  // onColumnChange(filterColumn.map((item,i)=>item?.col))
onColumnChange(newColumns);
};
const [update] = useUpdateFilterColumnsMutation()
const handleUpdate = async (e)=>{
  e.preventDefault()
  try{
   const res = await update({
      domain_name:isAuth?.user?.domain_name,
      data:filterColumn
   })
   console.log("res",res)
   if(res?.data?.status){
       refetch()
   }
  }catch(err){

  }
}
  return (
    <>
    <MetaHelmet title={meta_title}/>
      <div className="commonTable-search-container">
        <div className="commonTable-search-box">
          <input
            type="text"
            placeholder="Search by Job Id, Role, Title..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <FaSearch className="commonTable-search-icon" />
        </div>

        <div className="commonTable-dropdown-container">
          <button className="commonTable-create-btn-header"
            style={isAccessible.includes(isAuth?.user?.user_id)?{display:'block'}:{display:'none'}}
            onClick={handleButtonClick}>
            {headerButton} {headerIcon}
          </button>
        </div>
      </div>

      <div className="commonTable-container">
        {/* Search and Controls Section */}
        <div className="commonTable-header">
          <div className="commonTable-title">
            <button
              className="commonTable-filter-btn"
              onClick={() => setIsSidebarOpen(!isSidebarOpen)}
            >
              <FaBars /> View by
            </button>
            <p>{title}</p>
          </div>

          <div className="commonTable-controls">
            <button
              className="commonTable-filter-column-btn"
              onClick={() => setIsFilterOpen(!isFilterOpen)}
            >
              <FaFilter /> Filter Column
            </button>

            <button
              className="commonTable-sort-by-btn"
              onClick={() => setIsSortMenuOpen(!isSortMenuOpen)}
            >
              <FaSort /> Sort by
            </button>
          </div>
        </div>

        <div className="commonTable-wrapper">
          {/* Sidebar */}
          <div className={`commonTable-sidebar ${isSidebarOpen ? "show" : ""}`}>
            <div className="commonTable-sidebar-container">
              <h3>View by</h3>
              <button
                className="commonTable-close-btn"
                onClick={() => setIsSidebarOpen(false)}
              >
                <FaTimes />
              </button>
            </div>

            <div className="commonTable-filter-options">
              {/* {sidebarLabels.map((label, index) => (
                <label key={index}>
                  <input type="checkbox" /> {label}
                </label>
              ))} */}
              {sidebarLabels.map((label, index) => (
            <label key={index} onClick={() => handleFilterChange(label)}>
              <input type="radio" name="jobFilter" checked={selectedFilter === label} readOnly /> {label}
            </label>
          ))}
            </div>
          </div>
         
          {/* Table Section */}
          <div className="commonTable-scrollable-table">
            <table>
              <thead>
                <tr>
                  <th>Action</th>
                  {/* {columns.map((col) => (
                    <th key={col.key}>{col.label}</th>
                  ))} */}
                  {columns.map((col) =>
                selectedColumns.includes(col.key) ? <th key={col.key}>{col.label}</th> : null
              )}
                </tr>
              </thead>

              <tbody>
                {currentData.map((row, index) => (
                  <tr key={index}>
                    <td style={{ position: "relative" }}>
                      {/* <button
                        onClick={() => handleDropdownToggle(row.job_id)}
                        style={{ background: "transparent" }}
                      >
                        <BsThreeDots />
                      </button> */}
                      <button
                        onClick={() => handleDropdownToggle(row[rowIdentifier])}
                        style={{ background: "transparent" }}
                      >
                        <BsThreeDots />
                      </button>

                      {/* {openDropdown === row.job_id && (
                        <div className="commonTable-action-dropdown">
                          {onRowAction(row)}
                        </div>
                      )} */}
                      {openDropdown === row[rowIdentifier] && (
                        <div className="commonTable-action-dropdown">
                          {onRowAction(row,index)}
                        </div>
                      )}
                    </td>

                    {/* {columns.map((col) => (
                      <td key={col.key}>
                        {col.render ? col.render(row) : row[col.key]}
                      </td>
                    ))} */}
                    {columns.map((col,indx) =>
                  selectedColumns.includes(col.key) ? indx===1 || indx===0 ?<td style={{cursor:'pointer',color:'blue'}} className="commontable-hyper-link" onClick={(e)=>{
                    if(detailNavigate?.includes("candidates")){
                      navigate(`${detailNavigate}&id=${row[Object.keys(row)[0]]}&idx=${index}`)
                    }else{
                      navigate(`${detailNavigate}&id=${row[Object.keys(row)[0]]}`)
                    }
                  }} key={col.key}>{row[col.key]}</td>:<td key={col.key}>{row[col.key]}</td> : null
                )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>



        {/* Filter Menu */}
        {isFilterOpen && (
          <div className="commonTable-filter-menu-popup open">
            <div className="commonTable-filter-menu-header">
              <h3>Filter Column</h3>
              <button
                className="commonTable-filter-close-btn"
                onClick={() => setIsFilterOpen(false)}
              >
                <FaTimes />
              </button>
            </div>

            <div className="commonTable-filter-options">
              {/* {columns.map((col) => (
                <label key={col.key}>
                  <input type="checkbox" />
                  {col.label}
                </label>
              ))} */}
              {columns.map((col) => (
            <label key={col.key}>
              <input
                type="checkbox"
                //checked={selectedColumns.includes(col.key)}
                checked={parseInt(filterColumn?.find((item,i)=>item?.column_name===col?.key)?.is_enabled)===1}
                onChange={() => handleColumnSelection(col.key)}
              />
              {col.label}
            </label>
          ))}
            </div>
            <div className="commonTable-filter-actions">
              <button
                className="commonTable-cancel-btn"
                onClick={() => setIsFilterOpen(false)}
              >
                Cancel
              </button>
              <button onClick={(e)=> {handleUpdate(e)
                setIsFilterOpen(!isFilterOpen)}} className="commonTable-save-btn">Save Changes</button>
            </div>
          </div>
        )}

        {/* Sort Menu */}
        {isSortMenuOpen && (
          <div className="commonTable-sortby-menu">
            <h4>Sort By</h4>
            <button onClick={() => handleSortChange("Newest First")} className={sortOrder === "Newest First" ? "active" : ""}>Newest First</button>
            <button onClick={() => handleSortChange("Oldest First")} className={sortOrder === "Oldest First" ? "active" : ""}>Oldest First</button>
          </div>
        )}
      </div>
      {/* Pagination */}
        <div className="pagination-controls">
        <span>Go To Page</span>
        <input
          type="number"
          value={currentPage}
          onChange={(e) => goToPage(Number(e.target.value))}
          min="1"
          max={totalPages}
        />
        <select value={jobsPerPage} onChange={(e) => setJobsPerPage(Number(e.target.value))}>
          <option value="5">5 per page</option>
          <option value="10">10 per page</option>
          <option value="20">20 per page</option>
          <option value="50">50 per page</option>
        </select>
        <button onClick={() => goToPage(currentPage - 1)} disabled={currentPage === 1}>
          <GrFormPrevious />
        </button>

        {[...Array(totalPages)].map((_, index) => (
          <button
            key={index}
            onClick={() => goToPage(index + 1)}
            className={currentPage === index + 1 ? "active" : ""}
          >
            {index + 1}
          </button>
        ))}
        <button onClick={() => goToPage(currentPage + 1)} disabled={currentPage === totalPages || totalPages === 0}>
          <GrFormNext />
        </button>

      </div>
    </>
  );
};

export default CommonTable;
