const color = {
  fullWhite: '#FFFFFF',
  fullBlack: '#000000',
  borderColor: '#D2CFCF',
  primaryColor: '#007bff',
  headerBackground:'#2C3E50',
  activeStatus:'#069855',
  inactiveStatus:"#F8312F",
  pendingStatus:'#FCD53F',
  tableBackgroundRow:'#EBF6FF',
  buttonBlue:'#00BCD4',
  cardbackground:'#F5F7FA',
  borderbreakLine:'#E8E8E8',
  buttonBgLight:'#ebf6ff',
  borderLightBlue:'#a7d1ff'


};

const fonts = {
  font_bold: 'bold',
};

// Apply these colors as CSS variables
// export const applyTheme = () => {
//   const root = document.documentElement;
//   root.style.setProperty('--fullWhite', color.fullWhite);
//   root.style.setProperty('--fullBlack', color.fullBlack);
//   root.style.setProperty('--borderColor', color.borderColor);
//   root.style.setProperty('--primaryColor', color.primaryColor);
// };
// export const applyTheme = () => {
//   const root = document.documentElement;
//   Object.entries(color).forEach(([key, value]) => {
//     root.style.setProperty(`--${key}`, value);
//   });
// };

export const applyTheme = () => {
  const root = document.documentElement;
  Object.entries(color).forEach(([key, value]) => {
    root.style.setProperty(`--${key}`, value);
  });

};

export { color, fonts };
