import React, { useState, useEffect } from "react";

import "./DepartmentData.css";

import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  useCreateDepartmentsMutation,
  useGetCustomTemplateNameQuery,
} from "../../Redux/API/atsSlice";
import { useGetCustomerTemplateByIdQuery } from "../../Redux/API/atsSlice";
import DynamicTextInput from "../DynamicInputs/DynamicTextInput";
import { showToast } from "../ToastContainer/ToastProvider";
import { MdKeyboardArrowRight } from "react-icons/md";


const DepartmentData = () => {
  const [DropdownChange, setDropDownChange] = useState("");
  const [dropdownValue, setDropdownValue] = useState([]);
  const [customInputs, setCustomInput] = useState([]);
  const [Remove, setRemove] = useState(false);

  // console.log("dropdown value===>", dropdownValue);

  // console.log('dropdown change', DropdownChange);
  // console.log("custommm inputttttt===>", customInputs)

  const [formData, setFormData] = useState({
    departmentName: "",
    parentDepartment: "",
    departmentLead: "",
    attachment: null,
  });

  const isAuth = useSelector((state) => state.auth);
  const [params] = useSearchParams();
  // console.log("Pr",privilege.includes(isAuth?.user?.user_id))

  //Get the department data,template name and layout data from RTK query
  const { data: customLayoutNameData, isSuccess: CustomLayoutNameSuccess } =
    useGetCustomTemplateNameQuery({
      domain_name: isAuth?.user.domain_name,
      moduleName: params.get("type") ? params.get("type") : null,
    });
  // console.log("templatename",customLayoutNameData)

  const {
    data: customerLayoutIdData,
    isSuccess: CustomerLayoutIdSuccess,

    refetch: customerLayoutIdRefetch,
  } = useGetCustomerTemplateByIdQuery({
    domain_name: isAuth?.user.domain_name,
    templateId: DropdownChange || null,
  });

  // console.log("customLayoutIIddataa==>",customerLayoutIdData)

  useEffect(() => {
    if (customLayoutNameData?.status && customLayoutNameData?.message) {
      setDropdownValue(customLayoutNameData?.message || []);
      setDropDownChange(customLayoutNameData?.message[0]?.template_id || "");
    }
  }, [CustomLayoutNameSuccess]);

  useEffect(() => {
    customerLayoutIdRefetch();
    if (
      customerLayoutIdData?.status &&
      customerLayoutIdData?.message?.length > 0
    ) {
      setCustomInput(customerLayoutIdData?.message);
      console.log("updated datra ", customerLayoutIdData?.message);
    } else {
      setCustomInput([]);
    }
  }, [CustomerLayoutIdSuccess, DropdownChange, customerLayoutIdData]);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "attachment") {
      setFormData({ ...formData, [name]: files[0] });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  function formatDateForMySQL(dateString) {
    if (!dateString || dateString.trim() === "") {
      return null; // Return NULL if the value is empty
    }

    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return null; // Return NULL if invalid date
    }

    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const day = String(date.getUTCDate()).padStart(2, "0");

    return `${year}-${month}-${day}`; // Return formatted YYYY-MM-DD
  }

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   console.log("Form submitted:", formData);
  // };
  const [createDepartment] = useCreateDepartmentsMutation();
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = {};
      customInputs &&
        customInputs.forEach((section, i) => {
          section?.fields.forEach((item) => {
            if (item?.datatype === "boolean") {
              data[`${item?.ColumnName}`] =
                item?.valueOftheField === "true"
                  ? true
                  : item?.valueOftheField === "false"
                  ? false
                  : item?.valueOftheField === true
                  ? true
                  : false;
            } else if (item?.datatype === "int") {
              data[`${item?.ColumnName}`] = parseInt(item?.valueOftheField);
            } else if (item?.datatype === "date") {
              data[`${item?.ColumnName}`] = formatDateForMySQL(
                item?.valueOftheField
              );
            } else {
              data[`${item?.ColumnName}`] = item?.valueOftheField;
            }
          });
        });
      try {
        const response = await createDepartment({
          domain_name: isAuth?.user.domain_name,
          created_by: `${isAuth?.user?.firstname} ${isAuth?.user?.lastname}`,
          user_id: isAuth?.user?.user_id,
          data: {
            data,
            customInputs,
          },
        });
        console.log("Res", response);
        showToast(response.data.status, response.data.message);
        // if (response?.data?.status) {
        //     alert("Job Create Successfully")
        // }
      } catch (err) {
        console.log(err);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleCancel = () => {
    setFormData({
      departmentName: "",
      parentDepartment: "",
      departmentLead: "",
      attachment: null,
    });
  };

  const handleDropdownChange = (e) => {
    setDropDownChange(e.target.value);
  };
  const navigate = useNavigate();
  const handleEditClick = (e) => {
    if (DropdownChange && DropdownChange !== "0") {
      const isValidTemplate = dropdownValue.some(
        (item) => item.template_id === DropdownChange
      );

      if (isValidTemplate) {
        // window.location.href = `/ats/dashboard/?type=editDepartments&id=${DropdownChange}`;
        navigate(`/ats/dashboard/?type=editDepartments&id=${DropdownChange}`, {
          state: {
            moduleName: params.get("type") ? params.get("type") : null,
          },
        });
      } else {
        e.preventDefault();
      }
    } else {
      e.preventDefault();
    }
  };
  return (
    <div className="dept-form-container">
      <div className="dept-form-header">
        <label htmlFor="template-dropdown">Select Template:</label>

        <select
          id="template-dropdown"
          value={DropdownChange}
          onChange={(e) => {
            setDropDownChange(e.target.options[e.target.selectedIndex].value);
            console.log("gggg", e.target.options[e.target.selectedIndex].value);
          }}
          style={{ padding: "5px", width: "40%" }}
        >
          {dropdownValue &&
            [
              { template_name: "Choose an Template", template_id: 0 },
              ...dropdownValue,
            ].map((item, i) => {
              return (
                <option
                  key={item?.template_id}
                  label={item?.template_name}
                  value={item?.template_id}
                />
              );
            })}

          {/* <option value="Default Template">Default Template</option>
                        <option value="Template 1">Template 1</option>
                        <option value="Template 2">Template 2</option> */}
        </select>
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div className="create-dept-breadcrumb">
          <a
            href={`?type=job_openings`}
            className="create-dept-breadcrumb-dept-opening"
          >
            Job opening
          </a>
          <span className="create-dept-chevron">
            {" "}
            <MdKeyboardArrowRight size={23} />{" "}
          </span>
          <a className="create-dept-breadcrumb-link">Create Department</a>
        </div>
        <a href={`/ats/dashboard/?type=editDepartments&id=${DropdownChange}`}>
          <button
            style={{
              padding: "8px 25px",
              border: "1px solid blue",
              backgroundColor: "#ebf6ff",
              marginRight: "2.5rem",
            }}
            onClick={handleEditClick}
          >
            Edit
          </button>
        </a>
      </div>
      {customInputs &&
        customInputs.map((parent_item, parent_index) => {
          return (
            <>
              <p style={{ fontWeight: "bold", fontSize: "15pt" }}>
                {parent_item?.section}
              </p>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {parent_item?.fields &&
                  parent_item?.fields.map((item, i) => {
                    return (
                      <div>
                        <DynamicTextInput
                          className="border-b-2 focus:outline-none flex-1 mr-2"
                          key={i}
                          parentInd={parent_index}
                          remove={Remove}
                          onChangeRemove={setRemove}
                          index={i}
                          {...item}
                          value={customInputs}
                          customInputs={customInputs}
                          onChangeCustomInput={setCustomInput}
                          onChangeValue={setCustomInput}
                        />
                      </div>
                    );
                  })}
              </div>
            </>
          );
        })}
      <div className="dept-header-actions">
        {/* <button className="dept-button save-new">Save and New</button> */}
        <button
          className="dept-button save"
          onClick={handleSubmit}
        ></button>
        <button className="dept-button cancel" onClick={handleCancel}>
          Cancel
        </button>
      </div>
    </div>
  );
};

export default DepartmentData;
