import React, { useEffect, useState } from 'react';
import './DashBoardContent.css'
import { FaBriefcase, FaUserFriends, FaCalendarCheck } from 'react-icons/fa';
import JobOpening from '../JobOpening/JobOpening';
import { useGetCardsDataQuery } from '../../Redux/API/atsSlice';
import { useSelector } from 'react-redux';
import MetaHelmet from '../Helmet/Helmet';

const DashBoardContent = () => {
  const isAuth = useSelector((state) => state.auth)
  const [cardsData, setCardsData] = useState([])
  const { data, isSuccess, refetch } = useGetCardsDataQuery({
    domain_name: isAuth?.user?.domain_name,
  });
  console.log("cards data", data)
  useEffect(() => {
    if (data?.status && data?.message) {
      setCardsData(data?.message[0])

    }

  }, [isSuccess])

  console.log("datattaaa", cardsData)
  const active_jobs = cardsData?.active_jobs
  //const total

  const cards = [
    {
      icon: <FaBriefcase className='dbc-icon dbc-icon-purple' />,
      title: 'Job Posting',
      stats: [
        { label: 'Total Jobs', value: data?.message[0]?.total_job_count || 0 },
        { label: 'Active Jobs', value: data?.message[0]?.active_jobs || 0 },
        { label: 'Pending Approval', value: "-" },
      ],
      className: 'dbc-card dbc-card-purple'
    },
    {
      icon: <FaUserFriends className='dbc-icon dbc-icon-green' />,
      title: 'Candidates',
      stats: [
        { label: 'Total Candidates', value: data?.message[0]?.total_candidates || 0 },
        { label: 'Shortlisted', value: "-" },
        { label: 'Rejected', value: "-"},
      ],
      className: 'dbc-card dbc-card-green'
    },
    {
      icon: <FaCalendarCheck className='dbc-icon dbc-icon-blue' />,
      title: 'Interviews Scheduled',
      stats: [
        { label: 'This Week', value: data?.message[0]?.interview_this_week || 0 },
        { label: 'Pending Confirmations', value: "-" },
        { label: 'Completed Interviews', value: data?.message[0]?.past_interview || 0 },
      ],
      className: 'dbc-card dbc-card-blue'
    },
  ];
  return (
    <>
      <MetaHelmet title="Dashboard"/>
      <div className='dbc-main-container'>
        <div className='dbc-container'>
          <div className='dbc-cards-container'>
            {cards.map((card, index) => (
              <div className={card.className} key={index}>
                <div className='dbc-card-header'>
                  {card.icon}
                  <h3 className='dbc-card-title'>{card.title}</h3>
                  {/* <span className='dbc-menu'>...</span> */}
                </div>
                <div className='dbc-card-body'>
                  {card.stats.map((stat, idx) => (
                    <div key={idx} className='dbc-card-stat'>
                      <span>{stat.label}</span>
                      <span className='dbc-stat-value'>{stat.value}</span>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
          {/* <div className="dbc-joblisting-container" style={{ padding: "0" }}>
            <div style={{ padding: "0", margin: "0" }}>
              <JobOpening />
            </div>
          </div> */}

        </div>

      </div>
      <JobOpening />
    </>
  )
}

export default DashBoardContent
