import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { MdDragIndicator } from "react-icons/md";
import {
  useGetCustomerLayoutQuery,
  useGetDefaultTemplateQuery,
  useGettingTemplateNameByIdQuery,
  useUpdateTemplateByIdMutation,
} from "../../Redux/API/atsSlice";
import {
  CheckBoxInput,
  DateAndTimeInput,
  DateInput,
  DecimalInput,
  DropDownInput,
  EditorInput,
  EmailInput,
  FileInput,
  MultiSelectInput,
  NumberInput,
  RadioInput,
  TextInput,
  TimeInput,
} from "../CustomInputConfig/CustomInputConfig";
import { useGetCustomerTemplateByIdQuery } from "../../Redux/API/atsSlice";
import "./EditCreateJobLayout.css";
import DynamicTextInput from "../DynamicInputs/DynamicTextInput";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import { showToast } from "../ToastContainer/ToastProvider";
import { MdKeyboardArrowRight } from "react-icons/md";
import { CiSquarePlus } from "react-icons/ci";
import { RiDragMoveFill } from "react-icons/ri";
import createNewIcon from "../../Assests/createNewIcon.svg";
import { RiDeleteBin5Line } from "react-icons/ri";

const EditCreateJobLayout = ({ setHandleInput, handleInput }) => {
  const [showPopup, setShowPopup] = useState(false);
  const [isSectionShow, setisSectionShow] = useState(false);
  const [sectionName, setsectioName] = useState("");
  const location = useLocation();
  const isAuth = useSelector((state) => state.auth);
  // console.log("Log",location.state.moduleName)
  const [customInputs, setCustomInput] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [params] = useSearchParams();
  const {
    data: customerLayoutIdData,
    isSuccess: CustomerLayoutIdSuccess,
    refetch: CustomerLayoutRefetch,
  } = useGetCustomerTemplateByIdQuery({
    domain_name: isAuth?.user.domain_name,
    // templateId:params.get('type')?params.get('type'):null
    templateId: params.get("id") || null,
  });
  // console.log("Template",customInputs)
  const {
    data: getDefaultTemplate,
    isSuccess: getDefaultTemplateSuccess,
    refetch: getDefaultTemplateRefetch,
  } = useGetDefaultTemplateQuery({
    domain_name: isAuth?.user?.domain_name,
    moduleName: location.state.moduleName ? location.state.moduleName : null,
  });
  const [defaultTemplate, setDefaultTemplate] = useState();

  useEffect(() => {
    if (getDefaultTemplate?.status && getDefaultTemplate?.message.length > 0) {
      // console.log("getDefaultTemplate",getDefaultTemplate)
      setDefaultTemplate(getDefaultTemplate.message);
    }
  }, [getDefaultTemplateSuccess]);
  const [action, setAction] = useState("");

  const [updateTemplateById, { error }] = useUpdateTemplateByIdMutation();
  const getTemplateName = () => {
    const templateSet = new Set();
    try {
      customInputs &&
        customInputs.forEach((item, i) => {
          templateSet.add(item?.template_name);
        });
    } catch (err) {
      console.log(err);
    }
    return Array.from(templateSet);
  };
  //  console.log("templatename", getTemplateName()[0])
  const handleUpdateTemplateById = async (e) => {
    e.preventDefault();
    setCustomInput((prev) => {
      const sorted = prev.map((item, i) => ({
        ...item,
        index_of: i + 1,
        fields: item?.fields.map((ele, index) => ({
          ...ele,
          index_of: index + 1,
        })),
      }));
      return sorted;
    });
    try {
      if (title) {
        const response = await updateTemplateById({
          domain_name: isAuth?.user?.domain_name,
          templateId: params.get("id") ? params.get("id") : null,
          moduleName: location.state.moduleName
            ? location.state.moduleName
            : null,
          templateName:
            getTemplateName()?.length > 0 ? getTemplateName()[0] : null,
          data: customInputs,
          template_name: title,
        });
        // CustomerLayoutRefetch()

        showToast(response.data.status, response.data.message);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const { data, isSuccess } = useGetCustomerLayoutQuery({
    domain_name: isAuth?.user.domain_name,
  });
  const handleSaveChanges = () => {
    setShowPopup(true);
  };
  const [ButtonTitle, setButtonTitle] = useState("Save Changes");

  const handlePopupAction = (e, choice) => {
    e.preventDefault();
    setAction(choice);
    setShowPopup(false);
    if (choice === "update") {
      handleUpdateTemplateById(e);
      setButtonTitle("Save Changes");
      console.log("Updating existing template...");
    } else if (choice === "new") {
      // const newInput = customInputs && customInputs.map((item,i)=>{
      //    return  item?.system_default===1 ?{  ...item  } : null
      // }).filter((item,i)=> item!==null)
      //const newInput = cu
      //            console.log(newInput)
    }
  };
  const handleAddSection = (e) => {
    e.preventDefault();
    setisSectionShow(!isSectionShow);
    const obj = {
      section: sectionName,
      system_default: 0,
      index_of: customInputs.length + 1,
      fields: [],
    };
    const ordered = customInputs.map((item, i) => ({
      ...item,
      index_of: i + 1,
    }));
    setCustomInput([...ordered, obj]);
    setsectioName("");
  };
  // console.log("....................",customInputs)
  // useEffect(() => {
  //     if (data?.status && data?.message) {
  //         setCustomInput(data?.message)
  //     }
  // }, [isSuccess])
  // const [createJob] = useCreateJobPostingMutation()
  const [Remove, setRemove] = useState(false);
  const CustomTextButton = [
    {
      title: "Text Input",
      value: TextInput,
    },
    {
      title: "Email Input",
      value: EmailInput,
    },
    {
      title: "Date Input",
      value: DateInput,
    },
    {
      title: "File Input",
      value: FileInput,
    },
    {
      title: "DropDown Input",
      value: DropDownInput,
    },
    {
      title: "Number Input",
      value: NumberInput,
    },
    {
      title: "Multi Select Input",
      value: MultiSelectInput,
    },
    {
      title: "Editor Input",
      value: EditorInput,
    },
    {
      title: "Date and Time Input",
      value: DateAndTimeInput,
    },
    {
      title: "Decimal Input",
      value: DecimalInput,
    },
    {
      title: "Radio Input",
      value: RadioInput,
    },
    {
      title: "CheckBox Input",
      value: CheckBoxInput,
    },
    {
      title: "Time Input",
      value: TimeInput
    }
  ];
  const handleAddInput = (section_index, array) => {
    let new_field = array[0];
    const updatedArray = customInputs[section_index]?.fields.map((item, i) => ({
      ...item,
      index_of: i + 1,
    }));
    new_field.index_of = [...updatedArray].length;
    setCustomInput(
      customInputs.map((item, i) =>
        i === section_index
          ? {
            ...item,
            fields: [...updatedArray, new_field],
          }
          : { ...item }
      )
    );
    window.scrollTo({ top: document.body.scrollHeight, behaviour: "smooth" });
    // setHandleInput([...handleInput,array[1]])
  };
  console.log("message", customerLayoutIdData?.message);
  useEffect(() => {
    if (customerLayoutIdData?.status && customerLayoutIdData?.message) {
      // console.log("customerLayoutIdData")
      // setCustomInput((prev) => {
      //     const sortedArray = [...customerLayoutIdData?.message].sort((a, b) => a.index_of - b.index_of);
      //     const updateArray = sortedArray.map((item, i) => ({
      //         ...item,
      //         index_of: i + 1,
      //         fields: [...item?.fields].sort((a, b) => a.index_of - b.index_of)
      //     }))
      //     return updateArray
      // })

      setCustomInput(customerLayoutIdData?.message || []);
    } else {
      setCustomInput([]);
    }
  }, [CustomerLayoutIdSuccess]);

  const [readonly, setreadonly] = useState(customInputs.map((item) => true));
  const {
    data: TemplateNameById,
    isSuccess: TemplateNameByIdSuccess,
    refetch: TemplateNameByIdRefetch,
  } = useGettingTemplateNameByIdQuery({
    domain_name: isAuth?.user?.domain_name,
    templateId: params.get("id") ? params.get("id") : null,
  });
  useEffect(() => {
    if (TemplateNameById?.status) {
      setTitle(TemplateNameById?.message[0]?.template_name);
    }
  }, [TemplateNameByIdSuccess]);
  //       console.log("customerlayout by id=======>",customerLayoutIdData)
  const [title, setTitle] = useState();
  const handleDragEnd = (result, parent_index) => {
    if (!result.destination) return; // No valid drop location

    const reorderedList = [...customInputs[parent_index]?.fields];
    const [removed] = reorderedList.splice(result.source.index, 1);
    reorderedList.splice(result.destination.index, 0, removed);
    // const Ordered = [...reorderedList].map((item,i)=>({
    //     ...item,
    //     index_of:i+1
    // }))
    const Ordered = customInputs.map((item, i) =>
      i === parent_index
        ? {
          ...item,
          fields: [...reorderedList].map((item, i) => ({
            ...item,
            index_of: i + 1,
          })),
        }
        : { ...item }
    );
    setCustomInput(Ordered);
  };
  const handleDeleteSection = (e, index) => {
    e.preventDefault();
    try {
      setCustomInput((prev) => {
        const temp = [...prev];
        const updated = temp
          .filter((item, i) => i !== index)
          .map((item, i) => ({
            ...item,
            index_of: i + 1,
          }));
        return updated;
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleParentDragEnd = (result) => {
    if (!result.destination) return;
    const reorderedList = [...customInputs];
    const [removed] = reorderedList.splice(result.source.index, 1);
    reorderedList.splice(result.destination.index, 0, removed);
    const Ordered = [...reorderedList].map((item, i) =>
    // i === parent_index ?
    //     {
    //         ...item,
    //         fields: [...reorderedList].map((item, i) => ({
    //             ...item,
    //             index_of: i + 1
    //         }))
    //     }
    //     : { ...item }
    ({
      ...item,
      index_of: i + 1,
    })
    );
    setCustomInput(Ordered);
  };
  return (
    <>
      {/* <input
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        readOnly={true}
        className="template-name-heading"
      ></input> */}
      <div className="edit-create-job-header-container-oop">
        {/* Breadcrumb Navigation */}
        <div className="create-job-breadcrumb">
          <a
            href={`?type=job_openings`}
            className="create-job-breadcrumb-job-opening"
          >
            Job opening
          </a>
          <span className="create-job-chevron">
            {" "}
            <MdKeyboardArrowRight size={23} />{" "}
          </span>
          <a className="create-job-breadcrumb-link">Create Job</a>
        </div>
        <div>
          <button
            className="add-section-dynamically"
            onClick={(e) => {
              setisSectionShow(!isSectionShow);
              window.scrollTo({
                top: document.body.scrollHeight,
                behavior: "smooth",
              });
            }}
          >
            Add an Section
            <CiSquarePlus className="add-section-icon-style" />
          </button>
        </div>
      </div>
      <div className="main-edit-job-container">
        <div>
          <DragDropContext onDragEnd={(result) => handleParentDragEnd(result)}>
            <Droppable droppableId="sectionsList">
              {(provided) => (
                <div {...provided.innerRef} ref={provided.innerRef}>
                  {customInputs.map((parent_item, parent_index) => (
                    <Draggable
                      key={parent_item.index_of?.toString()}
                      draggableId={parent_item.index_of?.toString()}
                      index={parent_index}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className="each-section-styles"
                        >
                          <div className="each-section-hover-icon">
                            <RiDragMoveFill size={18} />
                          </div>
                          <div className="edit-job-section">
                            {/* Section Title Input */}
                            <input
                              type="text"
                              style={{
                                fontWeight: "bold",
                                fontSize: "15pt",
                                outline: "none",
                                border: "none",
                              }}
                              readOnly={parent_item?.system_default}
                              value={parent_item?.section}
                              onChange={(e) => {
                                const updatedSections = customInputs.map(
                                  (item, idx) =>
                                    parent_index === idx
                                      ? { ...item, section: e.target.value }
                                      : item
                                );
                                setCustomInput(updatedSections);
                              }}
                            />

                            <button
                              className="editjob-delsection-btn"
                              disabled={parent_item?.system_default}
                              onClick={(e) =>
                                handleDeleteSection(e, parent_index)
                              }
                            >
                              Delete <RiDeleteBin5Line size={17} />
                            </button>
                          </div>

                          {/* Dropdown to add fields in this specific section */}

                          {/* Delete Section Button */}



                          {/* Fields inside this section */}
                          <DragDropContext
                            onDragEnd={(result) =>
                              handleDragEnd(result, parent_index)
                            }
                          >
                            <Droppable
                              droppableId={`inputsList-${parent_index}`}
                            >
                              {(provided) => (
                                <div
                                  {...provided.droppableProps}
                                  ref={provided.innerRef}
                                  style={{ display: 'flex', flexDirection: 'column', rowGap: 30, alignItems: 'flex-end' }}
                                //   style={parent_item?.alignment==='row'?{display:'flex',flexDirection:'row',flexWrap:'wrap'}:parent_item?.alignment==='column'?{display:'flex',flexDirection:'column',flexWrap:'wrap'}:{}}
                                //   className="each-feild-conatiner"
                                //className={`each-feild-conatiner ${parent_item?.alignment}`}
                                >
                                  {parent_item?.fields &&
                                    parent_item?.fields.map((item, i) => (
                                      <Draggable
                                        key={
                                          item?.index_of?.toString() ||
                                          `fallback-${i}`
                                        }

                                        draggableId={
                                          item?.index_of?.toString() ||
                                          `fallback-${i}`
                                        }
                                        index={i}
                                      >
                                        {(provided) => (
                                          <div style={{ display: 'flex', alignItems: 'center', columnGap: 10 }}>
                                            <MdDragIndicator size={18} />
                                            <div
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}

                                              className="bg-white p-4 rounded-lg shadow-md mb-3 flex items-center justify-between"
                                            >
                                              <DynamicTextInput
                                                // customStyle={{
                                                //   display: "flex",
                                                //   flexDirection: "row",
                                                //   width: "20%",
                                                //   minWidth: "20%",
                                                //   maxWidth: "20%",
                                                // }}
                                                className="border-b-2 focus:outline-none flex-1 mr-2"
                                                key={i}
                                                parentInd={parent_index}
                                                remove={Remove}
                                                onChangeRemove={setRemove}
                                                index={i}
                                                {...item}
                                                value={customInputs}
                                                customInputs={customInputs}
                                                onChangeCustomInput={
                                                  setCustomInput
                                                }
                                                onChangeValue={setCustomInput}
                                              />
                                            </div></div>
                                        )}
                                      </Draggable>
                                    ))}
                                  {provided.placeholder}
                                </div>
                              )}
                            </Droppable>
                          </DragDropContext>
                          <div className="add-fields-container">
                            <button
                              className="add-fields-btn"

                              onClick={() =>
                                setShowDropdown(!showDropdown)}>

                              {showDropdown ? "Hide Fields" : "Add Fields"}
                              <img src={createNewIcon} className="add-field-btnicon" />
                            </button>

                            {/* Dropdown (Visible only when Add Fields is clicked) */}
                            {showDropdown && (
                              <select
                                style={{
                                  padding: "6px 18px",
                                  borderRadius: "8px",
                                  marginLeft: "2%",
                                }}
                                className="edit-create-job-dropdown"
                                onChange={(e) => {
                                  const selectedValue = CustomTextButton.find(
                                    (item) => item.title === e.target.value
                                  )?.value;
                                  if (selectedValue) {
                                    handleAddInput(parent_index, selectedValue);
                                    setShowDropdown(false);
                                  }
                                }}
                              >
                                <option value="" disabled selected>
                                  Select a field
                                </option>
                                {CustomTextButton.map((item, index) => (
                                  <option key={index} value={item.title}>
                                    {item.title}
                                  </option>
                                ))}
                              </select>
                            )}
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                </div>
              )}
            </Droppable>
          </DragDropContext>

          {isSectionShow && (
            <div>
              <label>Enter the Section Name</label>
              <input
                value={sectionName}
                onChange={(e) => setsectioName(e.target.value)}
              ></input>
              <button
                onClick={(e) => {
                  handleAddSection(e);
                }}
              >
                Save Changes
              </button>
              <button
                onClick={(e) => {
                  setisSectionShow(false);
                  setsectioName("");
                }}
              >
                Cancel
              </button>
            </div>
          )}
        </div>
      </div>
      <button className="editJob-savebtn" onClick={handleSaveChanges}>
        {ButtonTitle}
      </button>
      <button className="editjob-cancelbtn">Cancel</button>
      {showPopup && (
        <div className="popup-overlay">
          <div className="popup-content">
            <h3>What would you like to do?</h3>
            <p>
              Do you want to update the current template or create a new one?
            </p>
            <button onClick={(e) => handlePopupAction(e, "update")}>
              Update Existing
            </button>
            <button onClick={(e) => handlePopupAction(e, "new")}>
              Create New
            </button>
            <button
              onClick={() => setShowPopup(false)}
              className="popup-cancelbtn"
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default EditCreateJobLayout;