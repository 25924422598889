import React, { useEffect, useState } from "react";
import { useCreateHierachyMutation, useGetHierachyQuery, useGetUsersQuery } from "../../../../Redux/API/atsSlice";
import { useSelector } from "react-redux";
import "./Reportees.css";

const Reportees = () => {
    const isAuth = useSelector((state) => state.auth);
    const [Hierarchy, setHierarchy] = useState([]);
    const [expandedNodes, setExpandedNodes] = useState({});

    const { data: hierachy, isSuccess: hierachySuccess } = useGetHierachyQuery({
        domain_name: isAuth?.user?.domain_name,
    });

    useEffect(() => {
        if (hierachySuccess && hierachy?.status) {
            setHierarchy(hierachy?.message || []);
        }
    }, [hierachySuccess]);

    const toggleExpand = (h_id) => {
        setExpandedNodes((prev) => ({
            ...prev,
            [h_id]: !prev[h_id],
        }));
    };

    // Render the tree dynamically
    const renderTree = (parent_id = null) => {
        const nodes = Hierarchy.filter(node => node.parent_id === parent_id);
        if (nodes.length === 0) return null;

        return (
            <div className="tree-level">
            {nodes.map((node) => {
                const subordinates = Hierarchy.filter(item => item.parent_id === node.h_id);
                return (
                    <div key={node.h_id} className={`tree-node ${subordinates.length > 0 ? "has-children" : ""}`}>
                        <div className="node-content">
                            <p className="node-name">{node.label}</p>
                            <p className="node-role">({node?.value})</p>
                            {subordinates.length > 0 && (
                                <span className="expand-btn" onClick={() => toggleExpand(node.h_id)}>
                                    {expandedNodes[node.h_id] ? "-" : subordinates.length}
                                </span>
                            )}
                        </div>
                        {expandedNodes[node.h_id] && <div className="sub-tree">{renderTree(node.h_id)}</div>}
                    </div>
                );
            })}
        </div>
        );
    };

    return (
        <div className="tree-container">
            {renderTree()}
        </div>
    );
};

export default Reportees;
