
import { useState } from "react";
import { ArrowLeft, ArrowRight } from "lucide-react";
import "./AtsTestimonial.css";
import { useGetAtsHomePageQuery } from "../../Redux/API/atsSlice";
import { BASE_URL } from "../../Redux/API/apiSlice";

 const AtsTestimonial = () => {
  const [index, setIndex] = useState(0);
  const visibleTestimonials = 3;

  const { data: testimonial, isSuccess } = useGetAtsHomePageQuery();

  let parsedTestimonials = [];
  let testimonialHeading = "";

  if (isSuccess && testimonial) {
    const testimonialData = testimonial.message.find(
      (section) => section.section === "testimonials"
    );
    if (testimonialData) {
      testimonialHeading =
        testimonialData.values.find((val) => val.type === "h5")?.content ||
        "Testimonials & Success Stories";
      const rawContent = testimonialData.values.find(
        (val) => val.type === "array"
      )?.content;

      try {
        parsedTestimonials = rawContent ? JSON.parse(rawContent) : [];
      } catch (error) {
        console.error("Errortestimonials:", error);
      }
    } else {
      console.warn("Warning: No testimonial section found in API response!");
    }
  }

  const nextTestimonial = () => {
    if (index < parsedTestimonials.length - visibleTestimonials) {
      setIndex(index + 1);
    }
  };

  const prevTestimonial = () => {
    if (index > 0) {
      setIndex(index - 1);
    }
  };

  return (
    <div className="testimonial-container">
      <h2 className="testimonial-title">
        {testimonialHeading.includes("Testimonials") ? (
          <>
            <span className="highlight">
              {testimonialHeading.split("Testimonials")[0]}Testimonials
            </span>
            {testimonialHeading.split("Testimonials")[1]}
          </>
        ) : (
          testimonialHeading
        )}
      </h2>

      <div
        style={{ display: "flex", justifyContent: "end", marginRight: "50px"}}
      >
        <div className="testimonial-arrows">
          <button
            onClick={prevTestimonial}
            className="testimonial-arrow left"
            style={{ marginRight: "20px" }}
          >
            <ArrowLeft size={24} />
          </button>
          <button onClick={nextTestimonial} className="testimonial-arrow right">
            <ArrowRight size={24} />
          </button>
        </div>
      </div>

      <div className="testimonial-wrapper">
        <div
          className="testimonial-content"
          style={{
            transform: `translateX(-${index * (100 / visibleTestimonials)}%)`,
            width: `${parsedTestimonials.length * (100 / visibleTestimonials)}%`,
          }}
        >
          {parsedTestimonials.map((testimonial, i) => (
            <div
              key={i}
              className="testimonial-card"
              style={{ minWidth: `${100 / visibleTestimonials}%` }}
            >
              <div className="testimonial-header">
                {testimonial.icon && (
                  <img
                    src={`${BASE_URL}${testimonial.icon}`}
                    alt={testimonial.name}
                    className="testimonial-img"
                  />
                )}
                <div>
                  <h3 className="testimonial-name">{testimonial.name}</h3>
                  <p className="testimonial-role">{testimonial.designation}</p>
                </div>
              </div>
              <p className="testimonial-text">{testimonial.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default AtsTestimonial;


































