
import React, { useEffect, useState } from "react";
import { useGetCandidateByIdQuery, useGetCandidateSettingQuery, useGetCustomerCandidatesQuery, useSendCandidateEmailMutation } from "../../Redux/API/atsSlice";
import { useSelector } from "react-redux";
import ReactQuill, { Quill } from "react-quill";
import ImageResize from "quill-image-resize-module-react";
import "react-quill/dist/quill.snow.css";
import Select from "react-select";
import { useNavigate, useSearchParams } from "react-router-dom";
import CreatableSelect from 'react-select/creatable';
import Modal from "react-modal";
import '../Modal/Modal.css'
import "./CandidateDetail.css";
import { IoMdStarOutline } from "react-icons/io";
import { FaRegEdit } from "react-icons/fa";
import CandidateOverview from "./CandidateInfo/CandidateOverview/CandidateOverview";
import globe from "../../Assests/CandidateDetailImages/Glob.svg";
import linkedin from "../../Assests/CandidateDetailImages/Linkedin.svg";
import twitter from "../../Assests/CandidateDetailImages/Twitter.svg";
import twitterX from "../../Assests/CandidateDetailImages/twitterX.svg"
import github from "../../Assests/CandidateDetailImages/github.svg";
import coloredLinkedin from "../../Assests/CandidateDetailImages/ColoredLinkedin.svg";
import coloredGithub from "../../Assests/CandidateDetailImages/ColoredGit.svg";
import coloredX from "../../Assests/CandidateDetailImages/ColoredX.svg";
import coloredWeb from "../../Assests/CandidateDetailImages/Coloredweb.svg"
import share from "../../Assests/CandidateDetailImages/Sent.svg";
import calender from "../../Assests/CandidateDetailImages/Calander.svg";
import assignjob from "../../Assests/CandidateDetailImages/Assign Job.svg";
import sentemail from "../../Assests/CandidateDetailImages/SentEmail.svg"
import shareprofile from "../../Assests/CandidateDetailImages/Share Profile.svg";
import edit from "../../Assests/CandidateDetailImages/CandidateEdit.svg";
import { BASE_URL } from "../../Redux/API/apiSlice";
import { GoArrowLeft } from "react-icons/go";
import CandidateInfoDetail from "./CandidateInfo/CandidateDetail/CandidateInfoDetail";
import { showToast } from "../ToastContainer/ToastProvider";
import { GrNext } from "react-icons/gr";
import { GrPrevious } from "react-icons/gr";
import { IoCloseSharp } from "react-icons/io5";
import { IoClose } from "react-icons/io5";
import { Helmet } from "react-helmet";
import CandidateInterview from "./CandidateInfo/CandidateInterview/CandidateInterview";






Quill.register("modules/imageResize", ImageResize);
const CandidateDetail = () => {
  const [activeTab, setActiveTab] = useState("Details");
  const [candidateInfo, setCandidateInfo] = useState({});
  const [candidateAllInfo, setCandidateAllInfo] = useState(null);
  const [settingsInfo, setSettingsInfo] = useState([])
  const [isPopUpshow, setisPopShow] = useState({
    sendEmail: false
  })

  const [params] = useSearchParams();
  const [AllCandidates, setAllCandidates] = useState([])
  const isAuth = useSelector((state) => state.auth);
  const [index, setindex] = useState(parseInt(params.get('idx')))
  const navigate = useNavigate();


  const [sendEmail, setSendEmail] = useState({
    to_email: candidateInfo?.email || '',
    cc_email: [],
    bcc_email: [],
    sent_by_id: isAuth?.user?.user_id,
    subject: "",
    body: ""
  })

  const { data: candidatesData } = useGetCustomerCandidatesQuery({
    domain_name: isAuth?.user?.domain_name,
    role_id: isAuth?.user?.role_id,
    user_id: isAuth?.user?.user_id,
  });


  useEffect(() => {
    if (candidatesData?.status) {
      setAllCandidates(candidatesData?.message?.map((item, i) => item?.candidate_id))
    }
  }, [index, candidateInfo])


  const { data, isSuccess, refetch } = useGetCandidateByIdQuery({
    domain_name: isAuth?.user?.domain_name,
    candidateid: params.get("id") || "null",
  });


  const { data: settingData, isSuccess: settingSuccess, refetch: settingRefetch } = useGetCandidateSettingQuery({
    domain_name: isAuth?.user?.domain_name
  })

  useEffect(() => {
    if (settingSuccess && settingData?.status) {
      setSettingsInfo(settingData?.message[0])

    }
  }, [settingSuccess, settingData]);


  useEffect(() => {
    refetch();
  }, []);


  useEffect(() => {
    if (isSuccess && data?.status) {
      setCandidateAllInfo(data);
      const formattedData = {
        firstName: data?.message?.find((sec) => sec.section === "Basic Info")?.values?.find(val => val["First Name"])?.["First Name"] || "-",
        lastName: data?.message?.find((sec) => sec.section === "Basic Info")?.values?.find(val => val["Last Name"])?.["Last Name"] || "",
        email: data?.message?.find((sec) => sec.section === "Basic Info")?.values?.find(val => val["Email"])?.["Email"] || "-",
        phone: data?.message?.find((sec) => sec.section === "Basic Info")?.values?.find(val => val["Contact Number"])?.["Contact Number"] || "-",
        experience: data?.message?.find((sec) => sec.section === "Basic Info")?.values?.find(val => val["Experience"])?.["Experience"] ,
        jobTitle: data?.message?.find((sec) => sec.section === "Experience")?.values?.find(val => val["Current Job Title"])?.["Current Job Title"] || "-",
        company: data?.message?.find((sec) => sec.section === "Experience")?.values?.find(val => val["Company"])?.["Company"] || "-",
        createdAt: data?.message?.find((sec) => sec.section === "Candidate Info")?.values?.find(val => val["Created At"])?.["Created At"] || "-",
        modifiedAt: data?.message?.find((sec) => sec.section === "Candidate Info")?.values?.find(val => val["Modified On"])?.["Modified On"] || "-",
        createdBy: data?.message?.find((sec) => sec.section === "Candidate Info")?.values?.find(val => val["Created By"])?.["Created By"] || "",
        linkedinUrl: data?.message?.find((sec) => sec.section === "Basic Info")?.values?.find(val => val["LinkedIn URL"])?.["LinkedIn URL"] || "",
        website: data?.message?.find((sec) => sec.section === "Basic Info")?.values?.find(val => val["Website"])?.["Website"] || "",
        githubUrl: data?.message?.find((sec) => sec.section === "Basic Info")?.values?.find(val => val["Github URL"])?.["Github URL"] || " ",
        profile: data?.message?.find((sec) => sec.section === "Basic Info")?.values?.find(val => val["Profile Pic"])?.["Profile Pic"] || " "



      };
      setCandidateInfo(formattedData);
    }
  }, [isSuccess, data]);

  const editor = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image", "video"],
      ["clean"],
    ],
    clipboard: {
      matchVisual: false,
    },
    imageResize: {
      modules: ["Resize", "DisplaySize", "Toolbar"],
    },
  };

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
  ];
  const [sendMail] = useSendCandidateEmailMutation()
  const handleSendEmail = async () => {

    setSendEmail({
      ...sendEmail,
      to_email: candidateInfo?.email
    })

    try {
      const res = await sendMail({
        domain_name: isAuth?.user?.domain_name,
        candidateid: params.get('id'),
        data: sendEmail
      })

      showToast(res.data?.status, res.data?.message)
    } catch (err) {

    }
  }
  const getInitials = () => {
    const { firstName = "", lastName = "" } = candidateInfo || {};

    if (!firstName && !lastName) return "N/A";

    return `${firstName.charAt(0)}${lastName.charAt(0)}`.toUpperCase();
  };

  const formatDate = (dateString) => {
    if (!dateString || dateString === "N/A") return "N/A";

    const date = new Date(dateString);
    if (isNaN(date)) return "N/A";
    return new Intl.DateTimeFormat("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    }).format(date);
  };

  const handleChange = (type, e) => {
    e.preventDefault();
    let newIndex = index;

    if (type === 'next') {
      newIndex = index + 1;
      if (newIndex >= AllCandidates.length) return; // Prevent exceeding upper bound
    } else if (type === 'prev') {
      newIndex = index - 1;
      if (newIndex < 0) return; // Prevent negative index
    } else {
      return;
    }

    setindex(newIndex);
    navigate(`/ats/dashboard/?type=candidates&id=${AllCandidates[newIndex]}&idx=${newIndex}`);
    refetch();
  };
 
  const renderContent = () => {
    switch (activeTab) {
      case "Overview":
        return <CandidateOverview />;
      case "Details":
        return <CandidateInfoDetail candidateAllInfo={candidateAllInfo} />;
      case "Interviews":
        return <CandidateInterview/>;
      case "Evaluation":
        return <div>Evaluation Content</div>;
      case "Job Submission":
        return <div>Job Submission Content</div>;
      case "Comments":
        return <div>Comments Content</div>;
      case "Documents":
        return <div>Documents Content</div>;
      default:
        return <div>Overview Content</div>;
    }
  };

  return (
    <>
    <Helmet title="CandidateView"/>
    <div className="candidate-profile-container">
      <div className="candidate-profile-header">
        <div className="candidate-profile-info">

          <GoArrowLeft size={24}
            onClick={() => navigate("?type=candidates")}
            style={{ cursor: "pointer" }} />
          <div className="candidate-profile-avatar-wrapper">
            {/* <div className="candidate-profile-avatar">
              {candidateInfo?.profile ?(<img src={`${BASE_URL}${candidateInfo?.profile}`} className="candidate-profile-avatar"/> ):(<span className="candidate-initials">{getInitials()}</span>)}
              
            </div> */}
            <div className="candidate-profile-avatar">
              {candidateInfo?.profile && candidateInfo?.profile.trim() ? (
                <img src={`${BASE_URL}${candidateInfo?.profile}`} className="candidate-profile-avatar" />
              ) : (
                <span className="candidate-initials">{getInitials()}</span>
              )}
            </div>

            <div className="candidate-profile-social-icons">

              {/* if u want rating UI uncomment the code */}
              {/* <IoMdStarOutline />
              <IoMdStarOutline />
              <IoMdStarOutline />
              <IoMdStarOutline />
              <IoMdStarOutline /> */}
            </div>
          </div>
          <div>
            <p className="candidate-name">
              {candidateInfo.firstName} {candidateInfo.lastName} 
            </p>
            <p className="designation">{candidateInfo.jobTitle}</p>
            <div>
              {/* <img src={linkedin} alt="Linkedin" className="candidate-profile-social-iconsimg" />
              <img src={twitter} alt="Twitter" className="candidate-profile-social-iconsimg" />
              <img src={github} alt="GitHub" className="candidate-profile-social-iconsimg" />
              <img src={globe} alt="Website" className="candidate-profile-social-iconsimg" /> */}
              <div>

                {candidateInfo.linkedinUrl ? (
                  <a href={candidateInfo.linkedinUrl} target="_blank" rel="noopener noreferrer">
                    <img src={coloredLinkedin} alt="LinkedIn" className="candidate-profile-social-iconsimg"/>
                  </a>
                ):(
                <img src={linkedin} alt="LinkedIn" className="candidate-noprofile-social-iconimg" />
             
            )}


                {candidateInfo.twitterUrl ? (
                  <a href={candidateInfo.twitterUrl} target="_blank" rel="noopener noreferrer">
                    <img src={coloredX} alt="Twitter" className="candidate-profile-social-iconsimg" />
                  </a>
                ):(<img src={twitterX} alt="Twitter" className="candidate-noprofile-social-iconimg" />)}

                {candidateInfo.githubUrl ? (
                  <a href={candidateInfo.githubUrl} target="_blank" rel="noopener noreferrer">
                    <img src={coloredGithub} alt="GitHub" className="candidate-profile-social-iconsimg" />
                  </a>
                ):(<img src={github} alt="Github" className="candidate-noprofile-social-iconimg" />)}


                {candidateInfo.website ? (
                  <a href={candidateInfo.website} target="_blank" rel="noopener noreferrer">
                    <img src={coloredWeb} alt="Website" className="candidate-profile-social-iconsimg" />
                  </a>
                ):(<img src={globe} alt="Website" className="candidate-noprofile-social-iconimg" />)}
              </div>

            </div>
          </div>
        </div>
        <div className="candidate-profile-details">
          <div className="candidate-profile-group">
            <div><strong>Total Experience</strong>{candidateInfo.experience ? <p>{candidateInfo.experience} Years</p> : <p>-</p>}</div>
            <div><strong>Current Employer</strong><p>{candidateInfo.company}</p></div>
          </div>
          <div className="candidate-profile-group">
            <div><strong>Email</strong><p>{candidateInfo.email}</p></div>
            <div><strong>Phone</strong><p>{candidateInfo.phone}</p></div>
          </div>
          <div className="candidate-profile-group">
            <div className="candidate-profile-source-group">
              <div>
                <span className="source-label">Created On:</span>
                <span className="source-value">{formatDate(candidateInfo.createdAt)}</span>
              </div>
              <div>
                <span className="source-label">Modified On:</span>
                <span className="source-value">{formatDate(candidateInfo.modifiedAt)}</span>
              </div>
              <div>
                <span className="source-label">Source:</span>
                <span className="source-value">-</span>
              </div>
            </div>
            <div><strong>Owner</strong><p>{candidateInfo.createdBy}</p></div>
          </div>
          <div className="candidate-profile-group">
            <div className="candidate-profile-actions">

              <div className="candidate-profile-social-iconsimg candiidate-edit-img" onClick={() => navigate(`EditCandidate/${params.get("id")}`)}><img src={edit} /></div>
              {settingsInfo?.mail_enabled === 1 && <div onClick={(e) => setisPopShow({ ...isPopUpshow, sendEmail: !isPopUpshow.sendEmail })} className='candidate-profile-social-iconsimg'>
                <img src={sentemail} />
              </div>}
              {settingsInfo?.job_Assign === 1 && <div className='candidate-profile-social-iconsimg'>
                <img src={assignjob} />
              </div>}

              {settingsInfo?.submit_hiring_manager === 1 && <div className='candidate-profile-social-iconsimg'>
                <img src={shareprofile} />
              </div>}
              {settingsInfo?.schedule_interview === 1 && <div className='candidate-profile-social-iconsimg'>
                <img src={calender} />
              </div>}
              {/* <div className='candidate-profile-social-iconsimg' style={{marginTop:'5px',marginLeft:"5px", cursor: parseInt(index) === AllCandidates?.length - 1 ? 'not-allowed' : 'pointer',}} disabled={parseInt(index) === AllCandidates?.length - 1} onClick={(e) => handleChange('next', e)}><GrPrevious /></div>
              <div className='candidate-profile-social-iconsimg' style={{marginTop:'5px'}} disabled={parseInt(index) === 0} onClick={(e) => handleChange('prev', e)}><GrNext /></div> */}
              <div className='candidate-profile-social-iconsimg'
                style={{
                  marginTop: '5px',
                  marginLeft: "5px",
                  cursor: index >= AllCandidates.length - 1 ? 'not-allowed' : 'pointer',
                }}
                disabled={index >= AllCandidates.length - 1}
                onClick={(e) => handleChange('next', e)}
              >
                <GrPrevious />

              </div>
              <div className='candidate-profile-social-iconsimg'
                style={{
                  marginTop: '5px',
                  cursor: index <= 0 ? 'not-allowed' : 'pointer',
                }}
                disabled={index <= 0}
                onClick={(e) => handleChange('prev', e)}
              >
                <GrNext />
              </div>


            </div>
          </div>

        </div>
      </div>

      <div className="candidate-profile-tabs">
        {[ "Details","Overview","Interviews", "Evaluation", "Job Submission", "Comments", "Documents"].map((tab) => (
          <div
            key={tab}
            className={`candidate-profile-tab ${activeTab === tab ? "active" : ""}`}
            onClick={() => setActiveTab(tab)}
          >
            {tab}
          </div>
        ))}
      </div>

      <div className="candidate-profile-content">{renderContent()}</div>

      {/* <div className="candidate-profile-bottom-container">
        <div className="candidate-profile-button-container">
       
          <button className="candidate-profile-edit-btn" onClick={() => navigate(`EditCandidate/${params.get("id")}`)}>Edit</button>
          <button className="candidate-profile-next-btn" disabled={parseInt(index) === AllCandidates?.length - 1} onClick={(e) => handleChange('next', e)}>Previos</button>
          <button className="candidate-profile-next-btn" disabled={parseInt(index) === 0} onClick={(e) => handleChange('prev', e)}>Next</button>
        </div>
      </div> */}
      {/* <Modal isOpen={isPopUpshow.sendEmail}>
        <div>
          <p onClick={(e) => setisPopShow({ ...isPopUpshow, sendEmail: !isPopUpshow?.sendEmail })}>Close</p>
          <p>Send Email</p>
          <div style={{ display: 'flex' }}>
            <p>To</p>
            <input type="text" readOnly={true} value={candidateInfo?.email}></input>
          </div>
          <div style={{ display: 'flex' }}>
            <p>CC</p>
            <CreatableSelect
              isMulti
              value={sendEmail.cc_email}
              onChange={(selected) => {
                setSendEmail({
                  ...sendEmail,
                  cc_email: selected,
                });
              }}
              options={sendEmail.cc_email} // Ensure it's an array of objects
              onCreateOption={(inputValue) => {
                const newOption = { label: inputValue, value: inputValue };
                // console.log(newOption)
                setSendEmail({
                  ...sendEmail,
                  cc_email: [...(sendEmail.cc_email || []), newOption], // Append new email
                });
              }}
            />;

          </div>
          <div style={{ display: 'flex' }}>
            <p>BCC</p>
            <CreatableSelect
              isMulti
              value={sendEmail.bcc_email}
              onChange={(selected) => {
                setSendEmail({
                  ...sendEmail,
                  bcc_email: selected,
                });
              }}
              options={sendEmail.bcc_email} // Ensure it's an array of objects
              onCreateOption={(inputValue) => {
                const newOption = { label: inputValue, value: inputValue };
                // console.log(newOption)
                setSendEmail({
                  ...sendEmail,
                  bcc_email: [...(sendEmail.bcc_email || []), newOption], // Append new email
                });
              }}
            />;

          </div>
          <div style={{ display: 'flex' }}>
            <p>Subject</p>
            <input type="text" value={sendEmail.subject} onChange={(e) => setSendEmail({ ...sendEmail, subject: e.target.value })}></input>
          </div>
          <div style={{ display: 'flex' }}>
            <p>Body</p>
            <ReactQuill
              // style={{backgroundColor:'red'}}
              theme="snow"
              value={sendEmail.body}
              placeholder={"Enter the Body"}
              modules={editor}
              formats={formats}
              onChange={(html) => {
                setSendEmail({ ...sendEmail, body: html })
              }}
              style={{ width: "89vw" }}
              className="editor-input"
            />
          </div>
          <button onClick={handleSendEmail}>Send</button>
        </div>
      </Modal> */}






      
<Modal isOpen={isPopUpshow.sendEmail} className={`sent-email-modal ${isPopUpshow.sendEmail ? "show" : ""}`}>
  <div className="sent-email-container">
    {/* Header Section */}
    <div className="sent-email-header-container">
      <p className="sent-email-header">Send Email</p>
      <p
        className="sent-email-close"
        onClick={() =>
          setisPopShow({ ...isPopUpshow, sendEmail: !isPopUpshow?.sendEmail })
        }
      >
        <IoClose color="white" size={25}/>
      </p>
    </div>

   

    {/* CC and BCC Fields Side by Side */}
    <div style={{padding:"20px 20px 10px 20px"}}>
       {/* To Email Field */}
    <div className="sent-email-field">
      <p className="sent-email-label">To</p>
      <input
        type="text"
        readOnly={true}
        value={candidateInfo?.email}
        className="sent-email-input"
      />
    </div>
    <div className="sent-email-row">
      <div className="sent-email-field">
        <p className="sent-email-label">CC</p>
        <CreatableSelect
          isMulti
          className="sent-email-select"
          value={sendEmail.cc_email}
          onChange={(selected) => setSendEmail({ ...sendEmail, cc_email: selected })}
          options={sendEmail.cc_email}
          onCreateOption={(inputValue) => {
            const newOption = { label: inputValue, value: inputValue };
            setSendEmail({ ...sendEmail, cc_email: [...(sendEmail.cc_email || []), newOption] });
          }}
         
        />
      </div>

      <div className="sent-email-field">
        <p className="sent-email-label">BCC</p>
        <CreatableSelect
          isMulti
          className="sent-email-select"
          value={sendEmail.bcc_email}
          onChange={(selected) => setSendEmail({ ...sendEmail, bcc_email: selected })}
          options={sendEmail.bcc_email}
          onCreateOption={(inputValue) => {
            const newOption = { label: inputValue, value: inputValue };
            setSendEmail({ ...sendEmail, bcc_email: [...(sendEmail.bcc_email || []), newOption] });
          }}
        />
      </div>
    </div>

    {/* Subject Field */}
    <div className="sent-email-field">
      <p className="sent-email-label">Subject</p>
      <input
        type="text"
        value={sendEmail.subject}
        className="sent-email-input"
        onChange={(e) => setSendEmail({ ...sendEmail, subject: e.target.value })}
      />
    </div>

    {/* Email Body */}
    <div className="sent-email-field">
      <p className="sent-email-label">Body</p>
      <ReactQuill
        theme="snow"
        value={sendEmail.body}
        placeholder="Enter the Body"
        modules={editor}
        formats={formats}
        onChange={(html) => setSendEmail({ ...sendEmail, body: html })}
        // className="sent-email-editor"
      />
    </div>

    {/* Buttons */}
    <div className="sent-email-button-group">
      <button
        className="sent-email-cancel-button"
        onClick={() =>
          setisPopShow({ ...isPopUpshow, sendEmail: !isPopUpshow?.sendEmail })
        }
      >
        Cancel
      </button>
      <button className="sent-email-send-button" onClick={handleSendEmail}>
        Send
      </button>
    </div>
    </div>
  </div>
</Modal>




    </div>
    </>
  );
};

export default CandidateDetail;





