





// const Footer = () => {
//   const [footerItems, setFooterItems] = useState([]);
//   const [footerIcons, setFooterIcons] = useState([]);
//   const [footerLinks, setFooterLinks] = useState([]);

//   //Fetching the Data From RTK Query
//     const {
//         data,
//         refetch,
//         isSuccess,
//         isLoading
//     } = useGetMenuItemsQuery({})

//     // console.log("Footer",data," ",isLoading)
//   useEffect(() => {
//     const fetchFooterData = async () => {
//       try {
//         // const response = await fetch("http://hiremagic.in/api/getMenuItems");
//         // const data = await response.json();

//         const footerData = data.filter((item) => item.type_of_the_menu === "footer_item");
//         const footerIconsData = data.filter((item) => item.type_of_the_menu === "footer_icon");
//         const footerLinksData = data.filter((item) => item.type_of_the_menu === "footer_links");

//         setFooterItems(footerData);
//         setFooterIcons(footerIconsData);
//         setFooterLinks(footerLinksData);
//       } catch (error) {
//         console.error("Failed to fetch footer data:", error);
//       }
//     };

//     fetchFooterData();
//   }, [isSuccess]);

//   return (
//     <>
//     {
//       false?
//       <Loading/>:
//       <>
//         <footer className="footer">
//       <div className="footer-content">
//         {footerItems.map((item) => (
//           <div className="footer-section" key={item.id}>
//             <h4>{item.name_of_the_menu}</h4>
//             {item.child.length > 0 && (
//               <ul>
//                 {item.child.map((child) => (
//                   <li key={child.id}>
//                     <a href={child.route_name}>{child.name_of_the_menu}</a>
//                   </li>
//                 ))}
//               </ul>
//             )}
//           </div>
//         ))}
//       </div>

//       <div className="footer-social-section">
//         <div className="footer-social">
//           {footerIcons.map((icon) => (
//             <a
//               key={icon.id}
//               href={icon.route_name}
//               target="_blank"
//               rel="noopener noreferrer"
//               aria-label="Social Media Icon"
//             >
//               <img
//                 src={icon.name_of_the_menu}
//                 alt="Social Media Icon"
//                 style={{ width: "24px", height: "24px" }}
//               />
//             </a>
//           ))}
//         </div>

//         <div className="footer-links">
//           {footerLinks.map((link, index) => (
//             <React.Fragment key={link.id}>
//               <a href={link.route_name}>{link.name_of_the_menu}</a>
//               {index < footerLinks.length - 1 && <span>|</span>}
//             </React.Fragment>
//           ))}
//         </div>
//       </div>

//       {/* CopyRights Component */}
//       {/* <CopyRights /> */}
      
//     </footer>
//     <div>
//         <CopyRights/>
//     </div>
//       </>
//     }
//     </>
//   );
// };

// export default Footer;


import "./Footer.css";
import { FaLinkedin, FaFacebookF, FaInstagram } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import img from '../../Assests/ATS-logo.png';
import { useGetAtsHomePageQuery } from "../../Redux/API/atsSlice";
import { useEffect, useState } from "react";
import { BASE_URL } from "../../Redux/API/apiSlice";

const Footer = () => {
  const [footerData, setFooterData] = useState([])
  const { data, isSuccess, isError } = useGetAtsHomePageQuery({})



  useEffect(() => {
    if (isSuccess && data?.message) {
      const footerSection = data.message.find(section => section.section === "footer");
    
      if (footerSection) {
        const allContent = footerSection.values
          .filter(val => val.type === "array")
          .map(val => {

            try {
              const parsedContent = typeof val.content === "string" ? JSON.parse(val.content) : [];
              return parsedContent.map(item => ({ ...item, position: val.position }));
            } catch (error) {
              console.error("Error parsing JSON:", error);
              return [];
            }
          })
          .flat();

        setFooterData(allContent);
      }
    }
  }, [isSuccess, data]);

 
  return (
    <>
      {/* <hr /> */}
      <footer className="footer">
        <div className="footer-container">
          <div className="footer-logo-section">

            <img src={`${BASE_URL}${footerData[0]?.logo}`} width={150} alt="icon" className="footer-logo-image" />
            <p className="footer-tagline">
              <strong>{footerData[0]?.moto ? footerData[0]?.moto : "Smart Hiring, Simplified."}</strong>
            </p>
          </div>

          <div className="footer-links">
            {footerData
              .filter(item => item.parent)
              .map((section, idx) => (
                <div className="footer-column" key={idx}>
                  <h4>{section.parent}</h4>
                  <ul>
                    {section.child?.map((feature, index) => (
                      <li key={index}>
                        <a href={feature.route}>{feature.name}</a>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
          </div>


          <div className="footer-newsletter">
            <div className="newsletter-content">
              <p>Subscribe Newsletter for product updates & insights.</p>
              <div className="newsletter-input">
                <input type="email" placeholder="Enter Your Email" />
                <button>Subscribe</button>
              </div>
            </div>


            <div className="social-icons-container">
              <div className="social-icons">
                {footerData
                  .filter(item => item["facebook-icon"] || item["instagram-icon"] || item["linked-icon"] || item["twitter-icon"])
                  .map((icon, index) => {

                    return (
                      <a key={index} href={icon.route || "#"} target="_blank" rel="noopener noreferrer">
                        {icon["facebook-icon"] && <img src={`${BASE_URL}${icon["facebook-icon"]}`} alt="Facebook" />}
                        {icon["instagram-icon"] && <img src={`${BASE_URL}${icon["instagram-icon"]}`} alt="Instagram" />}
                        {icon["linked-icon"] && <img src={`${BASE_URL}${icon["linked-icon"]}`} alt="LinkedIn" />}
                        {icon["twitter-icon"] && <img src={`${BASE_URL}${icon["twitter-icon"]}`} alt="Twitter" />}
                      </a>
                    );
                  })}

              </div>
            </div>

          </div>
        </div>


        <div className="footer-bottom">
          <div className="footer-bottom-head">
            {footerData
              .filter(item => item.position === "bottom" && item.name?.includes("©"))
              .map((copyright, index) => (
                <p key={index}>{copyright.name}</p>
              ))}
          </div>

          <div className="footer-bottom-links">
            {footerData
              .filter(item => item.position === "bottom" && item.name && item.route)
              .map((link, index) => (
                <a key={index} href={link.route}>{link.name}</a>
              ))}
          </div>
        </div>

      </footer>
    </>
  );
};

export default Footer;
