import React, { useState } from "react";
import CustomInput from "../../Components/CustomInput/CustomInput";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import ReCAPTCHA  from 'react-google-recaptcha';
import { useUserSignUpMutation } from "../../Redux/API/homeSlice";
import signup from '../../../src/Assests/signup.jpg'
import "./SignUp.css"
import atsimage2 from '../../Assests/loginImage.png'

const SignUp = () => {
  const [SignUpMutation] = useUserSignUpMutation();
  const[captchaValue,setCaptchaValue] = useState(null);
  const [Error, setError] = useState({
    domain: "",
    email: ""
  });
  
  // const SITE_KEY = "YOUR_GOOGLE_RECAPTCHA_SITE_KEY";
  //  const SITE_KEY = "6Ld0nAArAAAAAFvcjc4S4yxC0bBgrGrOlokS-0vG"; // main key
  const SITE_KEY ="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"; // testing key

  const validationSchema = yup.object({
    firstName: yup
      .string()
      .required("First name is required")
      .matches(/^[A-Za-z]+$/, "First Name must contain only letters"),
    lastName: yup
      .string()
      .required("Last name is required")
      .matches(/^[A-Za-z]+$/, "Last Name must contain only letters"),
    domainName: yup
      .string()
      .required("Domain name is required")
      .matches(/^[A-Za-z]+$/, "Domain name must contain only letters"),
    email: yup
      .string()
      .required("Email is required")
      .email("Invalid Email format"),
    password: yup.string().required("Password is required"),
    reEnterPassword: yup
      .string()
      .required("Please retype your password")
      .oneOf([yup.ref("password"), null], "Passwords must match"),
    agreedToCheckUp: yup
      .boolean()
      .oneOf([true], "You must agree to the terms and conditions"),
  });

  const initialValues = {
    firstName: "",
    lastName: "",
    domainName: "",
    email: "",
    password: "",
    reEnterPassword: "",
    agreedToCheckUp: false,
  };

  // const handleSubmit = async (values, { resetForm }) => {
  //   if(!captchaValue){
  //     alert("please verify that you are not Robot!");
  //     return;
  //   }
  //   try {
  //     const response = await SignUpMutation({
  //       firstname: values?.firstName,
  //       lastname: values?.lastName,
  //       domain_name: values?.domainName,
  //       password: values?.password,
  //       email_id: values?.email
  //     });
  //     if (response?.data?.status) {
  //       setError({
  //         domain: "",
  //         email: ""
  //       });
  //       resetForm();
  //     } else {
  //       if (response?.data?.message.includes("Domain")) {
  //         setError({ ...Error, domain: response?.data?.message });
  //       } else {
  //         setError({ ...Error, email: response?.data?.message });
  //       }
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  const handleSubmit = async (values, { resetForm }) => {
    if (!captchaValue) {
      alert("Please verify that you are not a robot!");
      return;
    }
  
    try {
      const response = await fetch("http://localhost:3001/verify-captcha", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          firstname: values.firstName,
          lastname: values.lastName,
          domain_name: values.domainName,
          password: values.password,
          email_id: values.email,
          captchaToken: captchaValue, // Sending the CAPTCHA response token
        }),
      });
  
      const data = await response.json();
  
      if (data.success) {
        alert("Sign-up successful!");
        resetForm();
        setCaptchaValue(null); // Reset CAPTCHA
      } else {
        alert("CAPTCHA verification failed.");
      }
    } catch (error) {
      console.error("Sign-up error:", error);
      alert("Something went wrong.");
    }
  };
  

  return (
    <div className="signup-container" >
      <div className="signup-image-container">
        <img src={atsimage2} alt="Sign up" className="signup-image" />
      </div>
      <div className="signup-form-container">
        
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, handleChange, handleBlur, errors, touched, resetForm }) => (
            <Form>
              <div className="form-row">
                <div className="signup-form-group half-width">
                  <CustomInput
                    type="text"
                    value={values.firstName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="First Name"
                    name="firstName"
                    error={errors.firstName && touched.firstName ? errors.firstName : ""}
                  />
                </div>
                <div className="signup-form-group half-width">
                  <CustomInput
                    type="text"
                    value={values.lastName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="Last Name"
                    name="lastName"
                    error={errors.lastName && touched.lastName ? errors.lastName : ""}
                  />
                </div>
              </div>

              {/* Domain Name Field */}
              <div className="signup-form-group">
                <CustomInput
                  type="text"
                  value={values.domainName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  label="Domain Name"
                  name="domainName"
                  error={errors.domainName && touched.domainName ? errors.domainName : ""}
                />
                <p className="error-message">{Error.domain}</p>
              </div>

              

              {/* Email Field */}
              <div className="signup-form-group">
                <CustomInput
                  type="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  label="Email"
                  name="email"
                  error={errors.email && touched.email ? errors.email : ""}
                />
                <p className="error-message">{Error.email}</p>
              </div>

              <div className="form-row">
                <div className="signup-form-group half-width">
                  <CustomInput
                    type="password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="Password"
                    name="password"
                    error={errors.password && touched.password ? errors.password : ""}
                  />
                </div>
                <div className="signup-form-group half-width">
                  <CustomInput
                    type="password"
                    value={values.reEnterPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="Re-enter Password"
                    name="reEnterPassword"
                    error={errors.reEnterPassword && touched.reEnterPassword ? errors.reEnterPassword : ""}
                  />
                </div>
              </div>

              <div className="checkbox-container">
                <Field 
                  type="checkbox"
                  id="agreedToCheckUp"
                  name="agreedToCheckUp"
                />
                <div>
                <label htmlFor="agreedToCheckUp" className="sign-up-checkbox-label terms-policy">
                By creating an account, you agree to the <a href="/terms">Terms of use</a> and <a href="/privacy">Privacy Policy</a>.
                </label>
                </div>
                <ErrorMessage
                  name="agreedToCheckUp"
                  component="div"
                  className="error-message"
                />
              </div>

              <div className="captcha-container">
                <ReCAPTCHA sitekey={SITE_KEY} onChange={(token) => setCaptchaValue(token)} />
              </div>
              <button type="submit" className="signUpbtn">
                Create an account
              </button>

              <div className="login-link">
                Already have an account? <a href="/login">Log in</a>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      {/* Image Section */}
      
    </div>
  );
};

export default SignUp;
