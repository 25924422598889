import React, { useState,useEffect } from 'react';
import './Features.css';
import jobpost from '../../Assests/jobpost.svg';
import jobpostfill from '../../Assests/jobpostfill.svg';
import arrow from '../../Assests/Arow.svg';
import calender from '../../Assests/calender.svg'
import calenderhover from '../../Assests/calenderhover.svg';
import message from '../../Assests/videocall.svg';
import stars from '../../Assests/aiResumeStars.svg';
import aiMain from '../../Assests/aiResumeMain.svg';
import aiMessage from '../../Assests/Aipop.png';
import notificationMain from '../../Assests/NotificationMain.svg';
import notificationShimmer from '../../Assests/NotificationShimmer.svg';
import dashboard from '../../Assests/Dashboard.svg';
import dashboard1 from '../../Assests/Dashboard1.svg';
import DashboardRole1 from '../../Assests/DashboardRole1.svg';
import DashboardRole2 from '../../Assests/DashboardRole2.svg';
import DashboardRole3 from '../../Assests/DashboardRole3.svg';
import FeaturesIcon from '../../Assests/FeaturesIcon.svg'
import { useGetAtsHomePageQuery } from '../../Redux/API/atsSlice';


const Features = () => {
  const [isHoveredAiResume, setIsHoveredAiResume] = useState(false);
  const [isHoveredJobPost, setIsHoveredJobPost] = useState(false);
  const [isHoveredCalender, setIsHoveredCalender] = useState(false);
  const [isHoveredNotification, setIsHoveredNotification] = useState(false);
  const [isHoveredAnalytics, setIsHoveredAnalytics] = useState(false)
  const [isHovered, setIsHoveredDashboard] = useState(false)
  const[features,setFeatures]=useState([])
  const[featureHeading,setFeatureHeading] =useState('')
  const{data,isError,isSuccess}=useGetAtsHomePageQuery({})

  


useEffect(() => {
  if (isSuccess && data?.message) {
    const featuresSection = data.message.find(section => section.section === "features");
    
    if (featuresSection) {
      const heading = featuresSection.values.find((val) => val.type === "h5")?.content || "";
      setFeatureHeading(heading);
      const rawContent = featuresSection.values.find(val => val.type === "array")?.content;
   

      try {
        if (typeof rawContent === "string") {
        
          const matches = rawContent.match(/\[\s*\{[\s\S]*\}\s*\]/g); 
          if (matches && matches.length > 0) {
            const validJSON = matches[matches.length - 1]; 
            const parsedContent = JSON.parse(validJSON);
            setFeatures(parsedContent);
          } else {
            console.error("No valid JSON array found in content");
            setFeatures([]);
          }
        } else {
          setFeatures([]); 
        }
      } catch (error) {
        console.error("Error parsing JSON:", error, rawContent);
        setFeatures([]); 
      }
    }
  }
}, [isSuccess, data]);

  const heights = isHoveredAnalytics ? [20, 50, 30, 80] : [30, 20, 60, 40];
  return (
    <>
      {/* AI Resume Screening start */}
      <div className='features-main-container'>
        <h1 className='feature-heading'>{featureHeading}</h1>
        <div className='feature-button-container'>
          <button className='features-button'>
          <img src={FeaturesIcon} alt='icon' className='feature-icon'/>
           Features</button>
          </div>
        <div className='side-side-sections'>
         
          <div className='aiResume-screening-container'
            onMouseEnter={() => setIsHoveredAiResume(true)}
            onMouseLeave={() => setIsHoveredAiResume(false)}>
            <div className='ai-resume-image-container'>
              <img  src={aiMain} alt='AI Resume' className='ai-resume' />
              <img src={aiMessage} alt='Ai Resume Message' className='ai-message-image' />
              <img src={stars} alt='stars' className='ai-stars' />
            </div>
            <div className='text-section'>
              <h4 className='title'>{features[0]?.title}</h4>
              <p className='description'>{features[0]?.description} </p>
            </div>
          </div>
          {/* AI Resume Screening End */}

          {/* Role Based DAshboard start */}
          <div className='role-based-dashboard-container'
            onMouseEnter={() => setIsHoveredDashboard(true)}
            onMouseLeave={() => setIsHoveredDashboard(false)}
          >
            <div className='dashboard-image-container'>
              <img src={dashboard} alt='dashboard' />
              <img src={dashboard1} alt='dashboard1' className='dashboard-board' />
              <img src={DashboardRole1} alt='Hiring Manager' className='dashboard-role1' />
              <img src={DashboardRole2} alt='Candidates' className='dashboard-role2' />
              <img src={DashboardRole3} alt='Hr/Recuiter' className='dashboard-role3' />
            </div>
            <div className='text-section'>
              <h4 className='title'>{features[1]?.title}</h4>
              <p className='description'>{features[1]?.description}</p>

            </div>
          </div>
          {/* Role BAsed Dashboard ends */}

          {/* jop posting start */}
          <div
            className="feature-jobPosting-container"
            onMouseEnter={() => setIsHoveredJobPost(true)}
            onMouseLeave={() => setIsHoveredJobPost(false)}
          >
            <div className="image-container">
              <img src={isHoveredJobPost ? jobpostfill : jobpost} alt="job-post" className="fea-job-post" />
              <img src={arrow} alt="arrow" className="arrow-icon" />
            </div>
            <div className="text-section">
              <h3 className="title">{features[2]?.title}</h3>
              <p className="description">{features[2]?.description}</p>
            </div>
          </div>

          {/* job posting end */}
        </div>

        <div className='side-side-sections'>
          {/* calender start */}
          <div
            className="hover-card"
            onMouseEnter={() => setIsHoveredCalender(true)}
            onMouseLeave={() => setIsHoveredCalender(false)}
          >
            <img
              src={message}
              alt="Message Icon"
              className="message-box"
            />


            <img
              src={isHoveredCalender ? calenderhover : calender}
              alt="Automated Interview Scheduling"
              className="calendar-image"
            />


            <div className="text-section">
              <h3 className="title">{features[3]?.title}</h3>
              <p className="description">
                {features[3]?.description}
              </p>
            </div>


          </div>
          {/* calender end */}
          {/* Notification Start */}
          <div className='notification-container'
            onMouseEnter={() => setIsHoveredNotification(true)}
            onMouseLeave={() => setIsHoveredNotification(false)}>
            <div className='notification-image-container'>
              <img src={notificationShimmer} alt='notification-shimmer' className='notification-shimmer' />
              <img src={notificationMain} alt='notification-image' className='notification-main' />
              <img src={notificationShimmer} alt='notification-shimmer' className='notification-shimmer-down' />
            </div>
            <div className='text-section'>
              <h4 className='title'>{features[4]?.title}</h4>
              <p className='description'>{features[4]?.description}</p>
            </div>
          </div>
          {/* Notification Ends */}
          {/* Analytics Starts */}
          <div className="Analytics-card"
            onMouseEnter={() => setIsHoveredAnalytics(true)}
            onMouseLeave={() => setIsHoveredAnalytics(false)}
          >
            <div className="chart-container">
              <div className="chart-bg">
                <div className="chart">
                  {heights.map((height, index) => (
                    <div
                      key={index}
                      className={`bar-wrapper`}
                    >
                      <div
                        // Apply "green" only for second bar
                        className={`bar ${index === 1 ? "green" : "dark"}`}
                        // Set dynamic height and align bottom
                        style={{ height: `${height}px`, alignSelf: "flex-end" }}
                      ></div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {/* Title and Description */}
            <div className='text-section'>
              <h3 className="title">{features[5]?.title}</h3>
              <p className="description">
                {features[5]?.description}
              </p>
            </div>
          </div>

        </div>
        {/* Analytics End */}
      </div>
    </>
  );
};

export default Features;