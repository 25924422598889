import React from "react";
import { Helmet } from "react-helmet";




const MetaHelmet = ({ title }) => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{title}</title>
                <meta name="description" content="A brief description of your page for SEO and social sharing." />
                <meta name="keywords" content="your, keywords, here" />
                <meta name="author" content="Your Name or Brand" />


                <meta property="og:title" content="Your Page Title" />
                <meta property="og:description" content="A brief description of your page." />
                <meta property="og:image" content="https://example.com/your-image.jpg" />
                <meta property="og:url" content="https://example.com/your-page" />
                <meta property="og:type" content="website" />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Your Page Title" />
                <meta name="twitter:description" content="A brief description of your page." />
                <meta name="twitter:image" content="https://example.com/your-image.jpg" />
                <meta name="twitter:site" content="@yourTwitterHandle" />

                <link rel="icon" href={"%PUBLIC_URL%/WinspireFavicon.ico"} type="image/x-icon" />
                {/* <link rel="shortcut icon" href={favicon} type="image/x-icon" /> */}
                <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
                <link rel="icon" type="image/x-icon" sizes="32x32" href="/favicon-32x32.png" />
                <link rel="icon" type="image/x-icon" sizes="16x16" href="/favicon-16x16.png" />
                <link rel="manifest" href="/site.webmanifest" />


                <link rel="canonical" href="https://example.com/your-page" />
            </Helmet>
        </div>
    )
}

export default MetaHelmet