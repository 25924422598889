import React ,{useState,useEffect}from 'react';
import './ForgotPassword.css'
import atsimage from '../../Assests/loginImage.png';
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomInput from '../../Components/CustomInput/CustomInput';
import { useSendMailMutation, useVerifyOTPMutation } from '../../Redux/API/homeSlice';
 


const ForgotPassword = () => {

  const [data,setdata] = useState({
    email:""
})
const [step,setstep] = useState(1)
const [verify,setverify] = useState({
    email:data?.email,
    otp:"",
    password:""
})
    // const handleSubmit=()=>{
    //     console.log("forgot password")
    // }
    const [Sendemail] = useSendMailMutation()
    const [updatePassword] = useVerifyOTPMutation()
 const validationSchema = Yup.object({
    email: Yup.string()
      .required("Email is required.")
      .email("Invalid email format."),
      otp:Yup.number().required("OTP is required"),
    password: Yup.string().required("Password is required."),
  });


  useEffect(()=>{
    setverify({
        ...verify,
        email:data?.email
    })
},[data])
const handleSend = async (e) =>{
  e.preventDefault()
    try{
        const res = await Sendemail({data:data})
      console.log(res)
        if(res.data?.status){
            setstep(step+1)
        }
    }catch(err){   
    }
}
const handleVerifyOTP = async (e)=>{
  e.preventDefault()
    setverify({
        ...verify,
        email:data?.email
    })

    try{
        const res = await updatePassword({data:verify})
        console.log(res)
        if(res.data?.status){
            setstep(step+1)
        }
    }catch(err){

    }
}
    const formik = useFormik({
        initialValues: {
          email: "",
          password: "",
          otp:""
        },
        validationSchema,
       // onSubmit: handleSubmit,
      });
  
      const { errors, touched } = formik;
  return (
    <div className="forgotpage-container">
    {/* Image Section */}
    <div className="forgotpage-image-container">
      <img src={atsimage} alt="Login" className="login-image" />
    </div>
    <div className="forgotpage-form-container">
      <h4 className="forgotpassword-heading">Forgot Your Password</h4>
      <p className='forgot-para'>Enter Your Email and We Will help you reset your password</p>
      <form>
        {/* Email Field */}
        
        <div className="login-form-group">
          {/* <CustomInput
            type="email"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            label="Email"
            error={errors.email && touched.email ? errors.email : ""}
            autoComplete="off"
          /> */}
          {
            step===1?
            <CustomInput
            type="email"
            name="email"
            value={data?.email}
            onChange={(e)=>setdata({email:e.target.value})}
           
            label="Email"
       //     error={errors.email && touched.email ? errors.email : ""}
            autoComplete="off"
          /> :step===2?
          <CustomInput
          type="text"
          name="text"
          value={verify.otp}
          onChange={(e)=>setverify({...verify,otp:e.target.value})}
       //   onBlur={formik.handleBlur}
          label="OTP"
       //   error={errors.otp && touched.otp ? errors.otp : ""}
          autoComplete="off"
        />:step===3?
        <CustomInput
          type="text"
          name="text"
          value={verify.password}
          onChange={(e)=>setverify({...verify,password:e.target.value})}
         // onBlur={formik.handleBlur}
          label="Password"
        //  error={errors.password && touched.password ? errors.password : ""}
          autoComplete="off"
        />:<></>
          }
        </div>
        {
          step===1? <button type="submit" onClick={(e)=>handleSend(e)} className="forgotpage-button">
          Send email
        </button>:<button type="submit" onClick={(e)=>handleVerifyOTP(e)} className="forgotpage-button">
          {step===2?"Verify":step===3?"Reset":""}
        </button>
        }
          {/* <button type="submit" className="forgotpage-button">
          Send email
        </button> */}
        <div className="forgotpage-link">
             <a href="/login">Back To Login</a>
          </div>


      </form>
    </div>

  </div>
  )
}
export default ForgotPassword
