import "./AtsHero.css";
import { useEffect, useState } from "react";
import { useGetAtsHomePageQuery } from "../../Redux/API/atsSlice";
import { BASE_URL } from "../../Redux/API/apiSlice";
import { applyTheme } from "../../Common/theme/theme";

const AtsHero = () => {
  const [headingSmall, setHeadingSmall] = useState("");
  const [headingLarge, setHeadingLarge] = useState("");
  const [buttons, setButtons] = useState([]);
  const [foregroundImage, setForegroundImage] = useState("");
  const [backgroundImage, setBackgroundImage] = useState("");

  const { data: hero, isSuccess, refetch } = useGetAtsHomePageQuery({}, { refetchOnMountOrArgChange: true });

  // Refetch on mount
  useEffect(() => {
    refetch();
  }, [refetch]);

  // Extract hero section data on success
  useEffect(() => {
    if (isSuccess && hero?.message) {
      const heroSection = hero.message.find((section) => section.section === "hero_section");
      if (heroSection) {
        setHeadingSmall(heroSection?.values.find((item) => item.type === "h5")?.content || "");
        setHeadingLarge(heroSection?.values.find((item) => item.type === "h3")?.content || "");

        // Extract buttons
        setButtons(heroSection.values.filter((item) => item.type === "button") || []);

        // Extract images
        const images = heroSection.values.filter((item) => item.type === "image").map((img) => img.content);
        setForegroundImage(images[0] ? `${BASE_URL}${images[0]}` : `${BASE_URL}public/hero_image.svg`);
        setBackgroundImage(images[1] ? `${BASE_URL}${images[1]}` : `${BASE_URL}hero_background.svg`);
      }
    }
  }, [hero, isSuccess]);

useEffect(() => {
    applyTheme(); // Apply theme colors on component mount
  }, []);

  return (
    <div className="ATS-landing-container" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className="ATS-landing-content">
        <h1>{headingSmall}</h1>
        <p>{headingLarge}</p>
        <div className="ATS-landing-btns">
          {buttons.map((btn, index) => (
            <a
              key={index}
              href={btn.route || "#"}
              className={`ATS-landing-${index === 0 ? "signup" : "btn"}`}
              style={{ textDecoration: "none" }}
            >
              {btn.content}
            </a>
          ))}
        </div>
      </div>
      <div className="illustration">
        <img src={foregroundImage} alt="Hiring Process" />
      </div>
    </div>
  );
};

export default AtsHero;
